import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle, obtenerValorConfig, replaceValue, obtenerValorUser } from '../services/configStyle'
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { withOrientationChange } from 'react-device-detect';
import { status, statusError, statusData, evento, validarParametroOtorgante, getNextStepIdentification } from '../services/data';
import Ayuda from './ayuda';

let AutorizacionDocumento = (props) => {

    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const [dataUser, setDataUser] = React.useState([])
    const [nombre, setNombre] = useState('<Nombre de Usuario aqui>')
    //const { isLandscape } = props;
    const [showHelp, setShowHelp] = useState(false);
    const [disabledCheck, setDisabledCheck] = useState(false);

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }

        let bloqueo = localStorage.getItem("bloqueo");
        let newDate = new Date().getTime()
        if (bloqueo === 'true') {
            if (newDate >= localStorage.getItem("time")) {
                localStorage.setItem("bloqueo", false);
                localStorage.setItem("time", 0);
            }
        }
        status("documento_autorizacion_page", "Documento de Autorización")
    }, [])


    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
        setNombre(obtenerValorUser(dataUser, "nombreCompleto", ''))

    }, [dataUser])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const aceptarTerminos = check => {
        setDisabledCheck(true)
        setDataUser([...dataUser, { description: "request_report_acepted", value: check }]);
        evento('Documento de Autorización', 'Click', { description: `He leído y acepto "${obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_3")}"` }, true);
        if (check) {
            let data = { documento_autorizacion_aceptado: true }
            statusData("documento_autorizacion", data)
            // DESCOMENTAR LO SIGUIENTE PARA VOLVER AL FLUJO ORIGINAL
            // let step = '/video_token'
            // if (validarParametroOtorgante(dataOtorgante, 'DISABLE_VIDEO_TOKEN')) {
            //     step = '/selfie'
            // }
            let step = getNextStepIdentification(dataOtorgante)
            setTimeout(() => {
                history.push(step);
            }, 1000);
        }
    };

    const printText = () => {
        let textToPrint = "";
        let textStorage = obtenerValorConfig(dataOtorgante, "TEXTO_DE_CONSENTIMIENTO_3");
        textToPrint = replaceValue(textStorage, "{USUARIO}", nombre);
        textToPrint = replaceValue(textToPrint, "{OTORGANTE}", obtenerValorConfig(dataOtorgante, "RAZON_SOCIAL"))
        if (textToPrint) {
            return textToPrint.split("{enter}").map(line => {
                return (<Fragment>{line} <br /></Fragment>);
            });
        } else {
            return textToPrint;
        }
    }

    const statusE = async () => {
        let data = "Cancelado"
        await statusError('documento_autorizacion_page', data, "cancelado")
        if (!window.opener) {
            setTimeout(() => {
                history.push(`/${apiKey}`)
            }, 300);
        }
    }

    return (
        <Fragment>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => setShowCancel(false)} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-secondary modal_btn_w">SÍ</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient">
                <Header ruta="autorizacion_documento" show={() => setShowHelp(true)} />
                <h5 className="left-align margin_4 text-color">
                    <b>{(obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_3", "FALSO") === "FALSO") ? ("") : obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_3")}</b>
                </h5>
                <div className="terms_container">
                    <div className="terms_bg_box">
                        <p align="left" className="left-align">
                            <h6 className="text-right text-small text-strong">
                                <small>
                                    <strong>
                                        {(obtenerValorConfig(dataOtorgante, "LUGAR_Y_FECHA_CONSENTIMIENTO_3", "FALSO") === "FALSO") ? ("") : (<Fragment>{obtenerValorConfig(dataOtorgante, "CIUDAD_LEGAL_DEL_OTORGANTE")} a {new Intl.DateTimeFormat("es-MX", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit"
                                        }).format(new Date())}</Fragment>)}
                                    </strong>
                                </small>
                            </h6>

                            {printText()}
                        </p>
                        <div className="terms_bg_box_gradient"></div>
                    </div>
                </div>
                <div className="check_container">
                    <form>
                        <div className="checkbox">
                            <label htmlFor="check_terms">
                                <input id="check_terms" type="checkbox" onChange={e => aceptarTerminos(e.target.checked)} disabled={disabledCheck} />
                                <span className="checkbox-decorator"><span className="check"></span></span>
                                He leído y acepto "{obtenerValorConfig(dataOtorgante, "TITULO_DEL_CONSENTIMIENTO_3")}".
                            </label>
                        </div>
                    </form>
                </div>
                <br />
                <br />
                <br />
                <Footer />
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {/*
                (isLandscape) ? (
                    <div className="rotate_device">
                        <div className="center_info_bx">
                            <img src="images/rotate_device.svg" height="100" alt="" />
                            <p>Por favor usa tu dispositivo en vertical<br />
                                <small>Gira tu dispositivo para continuar</small>
                            </p>
                        </div>
                    </div>
                ) : ("")
                */}
            {(localStorage.getItem("bloqueo") == 'true') ? (<div className="err_finale  animate__animated animate__slideInUp">
                <div className="center_checks">
                    <h5>Proceso no exitoso</h5>
                    <p>Has superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                    <p>Si deseas mayor información no dudes en contactarnos con el <b>Folio</b> de proceso <b>{localStorage.getItem("uuidUser")}</b> </p>
                    <br />
                    {(!window.opener) &&
                        <div className="action_buttons noscroll_screen">
                            <Link to={"/" + localStorage.getItem("apikeyOtorgante")} className="btn btn-raised btn-primary forcewidth100 main_bg_color">ENTENDIDO</Link>
                        </div>
                    }
                </div>
            </div>) : ("")}
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </Fragment>
    )
}
AutorizacionDocumento = withOrientationChange(AutorizacionDocumento)
export default AutorizacionDocumento

