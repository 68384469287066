import axios from 'axios';
import {
    obtenerValorConfig
} from '../services/configStyle';

const host = process.env.REACT_APP_URL
const servicio = process.env.REACT_APP_SERVICE


const correoH = window.location.hostname;
const token = process.env.REACT_APP_TOKEN_EVENTS;


const endpoints = {
    otorgante: `${host}/v1/sec_${servicio}params`,
    otorgantePublic: `${host}/v1/sec_${servicio}public_params`,
    otorgantePK: `${host}/v1/sec_${servicio}params_pk`,
    ocr: `${host}/v2/sec_${servicio}ocr`,
    pdftoken: `${host}/v1/sec_${servicio}token_numbers_pk`,
    score: `${host}/v2/sec_${servicio}query_score`,
    generarSMS: `${host}/v1/sec_${servicio}token_sms_ac`,
    validarSMS: `${host}/v1/sec_${servicio}verify_token_sms/verify`,
    otp: `${host}/v1/sec_${servicio}otp`,
    actualizarScore: `${host}/v2/sec_${servicio}score_update`,
    gps: `${host}/v1/sec_${servicio}address`,
    actualizarEstado: `${host}/v2/sec_${servicio}update_cycle`,
    getCP: `${host}/v1/sec_${servicio}postalcodes`,
    sendRenapo: `${host}/v1/sec_${servicio}valid_curp`,
    sendIne: `${host}/v1/sec_${servicio}valid_ine`,
    getDocuments: `${host}/v1/sec_${servicio}proccessdocuments`,
    sendComprobante: `${host}/v1/sec_${servicio}file`,
    sendAttestation: `${host}/v1/sec_${servicio}encolar_mensaje`,
    sendProfile: `${host}/v1/sec_${servicio}datauser_update`,
    // newVideoToken: `${host}/v2/sec_${servicio}video_token`,
    newVideoToken: `${host}/v2/sec_${servicio}video_token_ipn`,
    zip: `${host}/v1/sec_${servicio}nom_151`,
    sendHologram: `${host}/v1/sec_${servicio}id_calibrate`,
    sendStep: `${host}/v1/sec_${servicio}eventos`,
    sendEvent: `${host}/v1/sec_${servicio}celd_events`,
    evaluateImage: `${host}/v1/sec_${servicio}h_prediction/classify`,
    sendVideoTokenPDF: `${host}/v1/sec_${servicio}public_video_token`,
    renapo: `${host}/v1/sec_${servicio}valid_curp`,
    ine: `${host}/v1/sec_${servicio}valid_ine`,
    // nom151: `${host}/v1/sec_${servicio}async_zip_nom_151_correo`,
    nom151: `${host}/v1/sec_${servicio}flow_checker`,
    info_device: `${host}/v1/sec_${servicio}check_status_device`,
};

let CancelToken = axios.CancelToken;
let cancel = () => { };
let cancelPeticionRenapo = () => { };
let cancelPeticionIne = () => { };


axios.interceptors.request.use(async function (options) {
    options.headers["time_start"] = Date.now()
    return options;
}, function (error) {
    console.log('Request error: ', error);
    return Promise.reject(error);
});

export function actualizarEstado(objeto) {
    var data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.actualizarEstado,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function obtenerParametrosOtorgante(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgante,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerParametrosOtorgantePK(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgantePK,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerOCR(uuid, imagen, side, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("ine", imagen, side + "-" + (new Date().getTime()) + ".png");
    data.append("side", side);
    data.append("bucket", "dco-bucket-" + servicio.replace("_", ""));
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    //console.log("enviando:"+side + "-" + (new Date().getTime()) + ".png");
    return axios({
        method: 'post',
        url: endpoints.ocr,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

//uuid, mailto, nombre, nacimiento, curp, otorgante, vigencia, coincidencia, liveness,textoConsentimiento, apiKey 
export function enviarCorreoAceptacion(data) {
    return axios({
        method: 'post',
        url: endpoints.nom151,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'mail-host': correoH,
            'Accept': 'application/json'
        },
        data: data
    });
}

export function enviarZipAmarillos(datos) {
    return axios({
        method: 'post',
        url: endpoints.zip,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: datos
    });
}

export function obtenerTokenVideo(uuid) {
    return axios({
        method: 'post',
        url: endpoints.token,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        params: {
            uuid: uuid
        }
    });
}

export function actualizarScore(uuid, calle, colonia, cp, estado, municipio, numeroExt, gps, empresa) {
    var data = JSON.stringify({
        "update": "true",
        "uuid": uuid,
        "transaccion": localStorage.getItem("uuidTrx"),
        //"otorgante_uuid": localStorage.getItem("uuidOtorgante"),
        //"otorgante_name": empresa,
        "calle": calle,
        "colonia": colonia,
        "cp": cp,
        "estado": estado,
        "municipio": municipio,
        "numero": numeroExt,
        "gps": gps,
        "isDesktop": true
    });
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: data
    });
}

export function obtenerTokenPDFVideo(uuid) {
    return axios({
        method: 'post',
        url: endpoints.pdftoken,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: {
            uuid: uuid
        }
    });
}

export function verificarTokenVideo(uuid, token, video, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("created", token.created);
    data.append("expire", token.expire);
    data.append("videoBase64", video, "videoaceptacion.webm");
    data.append("bucket", "dco-bucket-" + servicio.replace("_", ""));
    data.append("folder", "video_aceptacion/");
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    data.append("process", "local");
    return axios({
        method: 'post',
        url: endpoints.verify,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function verificarTokenPDFVideo(uuid, token, video, isIOS, isMobileSafari) {
    var data = new FormData();
    data.append("uuidUser", uuid);
    data.append("created", token.created);
    data.append("expire", token.expire);
    data.append("videoBase64", video, "videoaceptacion.webm");
    data.append("bucket", "dco-bucket-" + servicio.replace("_", ""));
    data.append("folder", "video_aceptacion/");
    data.append("originOS", ((isIOS || isMobileSafari) ? "ios" : "android"));
    data.append("process", "local");
    return axios({
        method: 'post',
        url: endpoints.pdfverify,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function enviarImagen(uuid, file) {
    var data = new FormData();
    data.append("file", file, (new Date().getTime()) + "_frame.jpg");
    data.append("uuid", uuid);
    //console.log("Creando OTP");
    return axios({
        method: 'post',
        url: endpoints.otp,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancel = cancelar;
        }),
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

export function crearSMS(uuid, numero, area) {
    return axios({
        method: 'post',
        url: endpoints.generarSMS,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: {
            "client": {
                "uuid": uuid,
                "platform": "android"
            },
            "phone": {
                "number": numero,
                "areaCode": area
            }
        }
    });
}

export function validarSMSServicio(uuid, numero, area, codigo) {
    return axios({
        method: 'patch',
        url: endpoints.validarSMS,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: {
            "client": {
                "uuid": uuid,
                "platform": "android"
            },
            "phone": {
                "areaCode": area,
                "number": area + numero,
                "token": codigo
            }
        }
    });
}

export function obtenerScore(uuid, isDesktop) {
    let dataOtorgante = JSON.parse(localStorage.getItem("dataOtorgante"));
    let nombreOtorgante = (obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""));
    return axios({
        method: 'get',
        url: endpoints.score + '/' + uuid + '/' + localStorage.getItem("uuidTrx"),
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'X-Consumer-Custom-ID': localStorage.getItem("uuidOtorgante"),
            'X-Consumer-Username': nombreOtorgante
        },
        params: {
            isDesktop: isDesktop
        }
    });
}

export function obtenerEstadoEnlace(enlace) {
    return axios({
        method: 'get',
        url: enlace,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        }
    });
}

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const getImageDimensions = file => new Promise((resolved, rejected) => {
    var i = new Image();
    i.onload = function () {
        resolved({
            w: i.width,
            h: i.height
        });
    };
    i.src = file;
});

export function obtenerMaps(cp, uuid) {
    return axios({
        method: 'post',
        url: endpoints.gps,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: {
            address: {
                cp: cp,
                street: ""
            },
            uuid: uuid
        }
    })
}

export function obtenerCP(cp, uuid) {
    return axios({
        method: 'post',
        url: endpoints.getCP,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: {
            address: {
                cp: cp,
                street: ""
            },
            uuid: uuid
        }
    })
}

/* DOCUMENTOS */
export function getDocuments() {
    return axios({
        method: 'post',
        url: endpoints.getDocuments,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
        },
        data: {
            uuidUser: localStorage.getItem("uuidUser")
        }
    })
}

/* Comprobante */
export function enviarComprobante(file) {
    let dataOtorgante = JSON.parse(localStorage.getItem("dataOtorgante"))
    let ID_OTORGANTE = (obtenerValorConfig(dataOtorgante, 'ID_OTORGANTE', ""))
    if (ID_OTORGANTE !== "") {
        localStorage.setItem("uuidOtorgante", ID_OTORGANTE)
    }
    var data = new FormData();
    let ruta = localStorage.getItem("uuidOtorgante") + "/" + localStorage.getItem("uuidUser") + "/domicilio"
    data.append("bucket", "dco-bucket-" + servicio.replace("_", ""));
    data.append("folder", ruta);
    data.append("uuid", localStorage.getItem("uuidUser"));
    data.append("file", file, (new Date().getTime()) + "_comprobante.jpg");
    //console.log("Creando OTP");
    return axios({
        method: 'post',
        url: endpoints.sendComprobante,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        data: data
    });
}

// Enviar video
export function enviarVideoRostro(facefile, Acreated, Aexpire, isIOS) {
    let so = isIOS ? 'ios' : 'android';
    var data = new FormData();
    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    data.append("originOS", so);
    data.append("service_call", "videotoken-embedding");
    const ocrFront = JSON.parse(localStorage.getItem("ocrFront"))
    let passFlag = "false"
    if (ocrFront != null) {
        if (ocrFront.identificacionPasaporte) {
            passFlag = "true"
        }
    }
    data.append("identificacion_pasaporte", passFlag);
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    if (isIOS) {
        VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.mp4';
    }
    data.append("videotoken", facefile, VIDEO_OUTPUT_FILE);
    console.log("llene el form:", VIDEO_OUTPUT_FILE);

    return axios({
        method: 'POST',
        url: endpoints.newVideoToken,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancel = cancelar;
        }),
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),

        },
        data: data
    });
    //return requestValidateVideo(dataRequest);
}

// Enviar video holograma
export const enviarHologramaIdentificacion = (hologramFile, isIOS) => {
    let uuidUser = localStorage.getItem("uuidUser");
    let so = isIOS ? 'ios' : 'android';
    let data = new FormData();
    data.append("originOS", so);

    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    if (isIOS) {
        VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.mp4';
    }
    data.append("ine", hologramFile, VIDEO_OUTPUT_FILE);

    return axios({
        method: 'POST',
        url: endpoints.sendHologram,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            apikey: localStorage.getItem("apikeyOtorgante")

        },
        data: data
    });
}

export const enviarEvento = (objeto) => {
    let uuidUser = localStorage.getItem("uuidUser");
    let uuidTrx = localStorage.getItem("uuidTrx");
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante");
    //let data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.sendEvent,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'transaccion': uuidTrx,
            'cliente': uuidUser,
            'apikey': apikeyOtorgante,
            'xc-token': token,
        },
        data: objeto
    });
}

export function finalUpdateScore(objeto) {
    let uuidTrx = localStorage.getItem("uuidTrx");
    let cliente = localStorage.getItem("uuidUser");
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante");
    objeto.uuid = cliente;
    objeto.transaccion = uuidTrx;
    let data = JSON.stringify(objeto);
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': uuidTrx,
            'cliente': cliente,
            'apikey': apikeyOtorgante
        },
        crossdomain: true,
        data: data
    });
}

export function actualizarScoreInicioFlujo() {
    let uuid = localStorage.getItem('uuidUser');
    let transaccion = localStorage.getItem('uuidTrx');
    let otorgante_uuid = localStorage.getItem('uuidOtorgante')
    let data = JSON.stringify({
        "uuid": uuid,
        "transaccion": transaccion,
        otorgante_uuid: otorgante_uuid,
    });
    return axios({
        method: 'post',
        url: endpoints.actualizarScore,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante")
        },
        crossdomain: true,
        data: data
    });
}

export function cancelarRequest() {
    cancel();
}

//Evaluar imagen
export const enviarImagenEvaluar = (image, model, name, side) => {
    let uuidUser = localStorage.getItem("uuidUser");
    let data = new FormData();
    data.append("model", model);
    data.append("photo", image, name);
    data.append("useScore", true);
    data.append("event", side);
    return axios({
        method: 'POST',
        url: endpoints.evaluateImage,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': uuidUser,
            apikey: localStorage.getItem("apikeyOtorgante")

        },
        data: data
    });
}

// Enviar video
export function enviarVideoRostroPDF(headers, data) {
    return axios({
        method: 'POST',
        url: endpoints.sendVideoTokenPDF,
        headers: headers,
        data: data
    });
}

export function obtenerParametrosOtorgantePublic(idOtorgante) {
    return axios({
        method: 'get',
        url: endpoints.otorgantePublic + "/" + idOtorgante,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
        }
    });
}

/* Obtener headers */
const getHeaders = () => {
    let objeto = {};
    let uuidUser = localStorage.getItem("uuidUser") || '';
    let uuidTrx = localStorage.getItem("uuidTrx") || '';
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante") || '';

    objeto.cliente = uuidUser;
    objeto.transaccion = uuidTrx;
    objeto.apikey = apikeyOtorgante;

    return objeto;
}

/* RENAPO */

export const enviarRenapo = (curp) => {
    return axios({
        method: 'POST',
        url: endpoints.renapo,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionRenapo = cancelar;
        }),
        headers: {
            'apikey': getHeaders().apikey,
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
        },
        data: {
            "uuid": localStorage.getItem("uuidUser"),
            "turn": -1,
            "curp": curp
        }
    })
}

export const obtenerResultadoRenapo = (jobID) => {
    return axios({
        method: 'GET',
        url: endpoints.renapo,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionRenapo = cancelar;
        }),
        headers: {
            'apikey': getHeaders().apikey,
        },
        params: {
            job_id: jobID
        }
    })
}

/* INE */

export const enviarIne = (ocr, idCiudadano, claveElector, numeroEmision, modeloIdentificacion) => {
    let data = {
        "ocr": ocr,
        "clave_elector": claveElector,
        "numero_emision": numeroEmision,
        "id_ciudadano": idCiudadano,
    }
    return axios({
        method: 'POST',
        url: endpoints.ine,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionIne = cancelar;
        }),
        headers: {
            'apikey': getHeaders().apikey,
            //'Otorgante': getHeaders().cliente,
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
        },
        data: {
            "uuid": localStorage.getItem("uuidUser"),
            "turn": -1,
            "modeloIdentificacion": modeloIdentificacion,
            "data": data,
        }
    })
}

export const obtenerResultadoINE = (jobID) => {
    return axios({
        method: 'GET',
        url: endpoints.ine,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancelPeticionIne = cancelar;
        }),
        headers: {
            'apikey': getHeaders().apikey,
        },
        params: {
            job_id: jobID
        }
    })
}

export const cancelarRequestRenapo = () => {
    cancelPeticionRenapo();
}

export const cancelarRequestIne = () => {
    cancelPeticionIne();
}

export function generacionNom151(data) {
    return axios({
        method: 'post',
        url: endpoints.nom151,
        headers: {
            'apikey': getHeaders().apikey,
            'transaccion': getHeaders().transaccion,
            'cliente': getHeaders().cliente,
        },
        data: data
    });
}
/*------*/

/* RENAPO */

export const obtenerInfoDispositivo = () => {
    return axios({
        method: 'POST',
        url: endpoints.info_device,
        headers: {
            'apikey': getHeaders().apikey,
            'transaccion': getHeaders().transaccion,
            'uuid': getHeaders().cliente,
        }
    })
}

/*------*/

/*--- Nuevo servicio videotoken ---*/
export const  nuevoVideotoekn = (data) => {
    return axios({
        method: 'POST',
        url: endpoints.newVideoToken,
        cancelToken: new CancelToken(function executor(cancelar) {
            cancel = cancelar;
        }),
        timeout: 50000,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),

        },
        data: data
    });
    //return requestValidateVideo(dataRequest);
}
/*-------*/