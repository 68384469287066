import {
    actualizarEstado, enviarEvento, enviarHologramaIdentificacion, finalUpdateScore, enviarZipAmarillos, enviarImagenEvaluar, enviarCorreoAceptacion,
    enviarVideoRostroPDF, actualizarScoreInicioFlujo, enviarRenapo, enviarIne, obtenerResultadoINE, obtenerResultadoRenapo, generacionNom151, nuevoVideotoekn
} from '../services/api.js';
import {
    obtenerValorConfig,
    obtenerValorUser
} from './configStyle';
import moment from 'moment';

let objetoFinal = {};
let contadorRENAPO = 0, contadorINE = 0, cancelarPeticionINE = false, cancelarPeticionRENAPO = false;

export const agregarInfo = (dataUser, elemento) => {
    let dataUserLS = JSON.parse(localStorage.getItem("data_user"))
    const elementoEncontrado = dataUserLS.findIndex(function (item) {
        return item.description === elemento.description
    })
    ////console.log("elementoEncontrado" + elementoEncontrado + " item:"+elemento);
    if (elementoEncontrado !== -1) {
        dataUserLS[elementoEncontrado] = elemento
    } else {
        dataUserLS.push(elemento)
    }
    localStorage.setItem("data_user", JSON.stringify(dataUserLS))
}


export const hayExcepcion = () => {
    const uuidExcepcion = ["Wco5CTXw7e0JoKR9p7TB9fqOjpUS7iuu", "VCVi90WBhqtRPWboXtfpHBQlLPO00jd4", "MQZq6jzleILkHbQhGULIdwPhn5Jv19HD"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const regresarJson = () => {
    const uuidExcepcion = ["iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const esEditable = () => {
    //lso campos son editables para CDC y otorgantes
    const uuidExcepcion = ["0zmV8ljIWoFfGsaieC4vuEPwpVKYYrAP", "MTMba54LICmL2Boez3KuFdVL1vD1boKk", "7a531a75-e0cf-4158-bd99-86b733ef22ad", "94b502fd-5546-44e1-b12f-2122d410cea0"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    //console.log("respuesta", respuesta);
    return respuesta
}

export const initStatus = (nombreOtorgante) => {
    //console.log("entre a initStatus");
    const referer = localStorage.getItem('externalReference');
    updateInitStatus(nombreOtorgante);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: "inicio_page",
            result: "OK",
            //data: { transaccion: localStorage.getItem("uuidTrx")}
        }), referer)
        //console.log("hay referer");
        window.opener.postMessage(JSON.stringify({
            step: "inicio",
            result: "OK",
            data: {
                uuid: localStorage.getItem("uuidUser")
            }
        }), referer)
        /* localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalJWT", new URL(window.location).searchParams.get("jwt")) */
    }
    /* if (new URL(window.location).searchParams.get("referer")) {
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
    }
    if (new URL(window.location).searchParams.get("schema")) {
        localStorage.setItem("package", new URL(window.location).searchParams.get("schema"))
    } */
}

const updateInitStatus = async (nombreOtorgante) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let trx_id = localStorage.getItem("uuidTrx");
        let json = {
            "status": "OK",
            "step": "inicio"
        }
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        let event = JSON.stringify(json);
        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'step': "inicio_page",
            'event': event,
            'origin': "celd",
            'nombreOtorgante': nombreOtorgante
        }
        //console.log("uuidtx:", localStorage.getItem("uuidTrx"));
        actualizarEstado(objetoStatus).then(({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = status;
                informacion.dataLifeCycle = objetoStatus;
                tipo = 'Update Cycle';
                evento('Inicio', tipo, informacion, true);
                initUpdateScore();
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento('Inicio', tipo, informacion, false);
        })
    } else {
        return
    }
}

export const status = (paso, updatepaso) => {
    let json = {
        "status": "OK",
        "step": updatepaso
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK"
        }), localStorage.getItem("externalReference"))
    }
}

export const statusData = (paso, datos) => {
    var json = {};
    json = {
        "status": "OK",
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK",
            data: datos
        }), localStorage.getItem("externalReference"))
    }
}

export const statusError = async (paso, datos, tipo) => {
    //console.log("entre a status cancelar");
    var json = {};
    let status = "INCOMPLETED"
    if (tipo !== "cancelado") {
        status = "FAIL"
    }
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }

    if (tipo === "cancelado") {
        json.finished = true;
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    await updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            status: 'INCOMPLETED',
            type: "PROCESS",
            error_text: datos,
        }), localStorage.getItem("externalReference"))
        // if (tipo === "cancelado")
        //     window.close()
    }
}

const updateStatus = async (objetoInformacion) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let dataEvent = null;
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));
        const bandera = localStorage.getItem("flag");

        let nombreCompleto = dataUser ? obtenerValorUser(dataUser, "nombreCompleto", "") + " " : '';
        let telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        let correo = localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        let flag = bandera ? bandera : '';
        let tipoIdentificacion = localStorage.getItem("typeID");

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': nombreCompleto,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag,
            'typeID': tipoIdentificacion ? tipoIdentificacion : '',
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        dataEvent = JSON.parse(objetoInformacion.event);

        return actualizarEstado(objetoFinal).then(async ({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = dataEvent.status;
                informacion.dataLifeCycle = objetoFinal;
                tipo = 'Update Cycle';
                return await evento(dataEvent.step, tipo, informacion, true);
            }
        }).catch(async (error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            await evento(dataEvent.step, tipo, informacion, false);
        })
    } else {
        return
    }
}

/* Validaciones */

export const hayLetras = (input) => {
    let result = true
    var expreg = /[a-z]/gi;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    return result
}

export const esNumLetras = (input) => {
    let result = true
    let expreg = /^[A-Za-z0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esNumLetras",result);
    return result
}

export const esSoloLetras = (input) => {
    let result = true
    let expreg = /^[A-Za-z-zÀ-ú\s]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esSoloLetras",result);
    return result
}

export const esNumeros = (input) => {
    let result = true
    let expreg = /^[0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esnum",result);
    return result
}

export const statusReintento = (paso, datos, tipo) => {
    let json = {};
    let status = tipo
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }

    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }

    return updateStatus(objetoStatus);
}

export const sendData = (objetoStatus) => {
    return updateStatus(objetoStatus);
}

export const calculateX = (innerWidth, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerWidth * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerWidth * percent / 100));
    }
};

export const calculateY = (innerHeight, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerHeight * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerHeight * percent / 100));
    }
};

export const calculateWidth = (innerWidth, percent, tipo) => {
    //console.log("Device Ratio:",ratio);
    if (tipo === "string") {
        return (Math.ceil(innerWidth * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerWidth * percent / 100));
    }
};

export const calculateHeight = (innerHeight, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerHeight * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerHeight * percent / 100));
    }
};

export const formatoFechaOcr = (fecha) => {
    let formatosP = ['DD/MM/YYYY', 'DD/MM/YY']
    let nacimientoC = fecha
    if (fecha.includes(' '))
        nacimientoC = fecha.replaceAll(' ', '/')
    if (moment(nacimientoC, formatosP, true).isValid())
        return nacimientoC;
    else {
        return "";
    }
}

export const formatoFechaCuatroDigitos = (fecha) => {
    let añoActualInt = new Date().getFullYear().toString().substr(-2);
    let añoFechaOcr = fecha.substr(-2);
    let añoFechaOcrInt = fecha.substr(-2);
    let fechaSinAño = fecha.substr(0, 6);
    let fechaOcr = '';
    if (añoFechaOcrInt >= añoActualInt) {
        // console.log("entre 19");
        fechaOcr = fechaSinAño + '19' + añoFechaOcr;
    } else {
        let mayoriaDeEdad = añoActualInt - 18;
        if (añoFechaOcrInt <= mayoriaDeEdad) {
            // console.log("entre 20");
            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
        } else {
            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
        }

    }
    return fechaOcr;
}

export const validarValoresFormulario = (valores) => {
    let validacion = {
        errores: {},
        correcto: true
    };
    Object.entries(valores).forEach(([key, value]) => {
        let campoValidado = validarCampo(value, key);
        if (!campoValidado.correcto) {
            validacion.errores[key] = campoValidado.errores[key];
            validacion.correcto = false;
            return validacion;
        }
    });

    return validacion;
};

export const validarCampo = (valor, campo) => {
    let validacion = {
        errores: {},
        correcto: false
    };
    let formatosFecha = ['DD/MM/YYYY'];
    switch (campo) {
        case "nombreCompleto":
            if (!valor.trim()) {
                validacion.errores.nombreCompleto = 'Debes ingresar un valor válido';
            } else if (valor.length < 10 || !esSoloLetras(valor)) {
                validacion.errores.nombreCompleto = 'Nombre no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "fechaNacimiento":
            if (!valor.trim()) {
                validacion.errores.fechaNacimiento = 'Debes ingresar un valor válido';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.correcto = true;
            } else {
                validacion.errores.fechaNacimiento = 'Revisa el formato dd/mm/aaaa';
            }
            break;
        case "clave":
            if (!valor.trim()) {
                validacion.errores.clave = 'Debes ingresar un valor válido';
            } else if (!esNumLetras(valor) || valor.length < 18 || valor.length > 18) {
                validacion.errores.clave = 'Clave de elector no válida';
            } else {
                validacion.correcto = true;
            }
            break;
        case "fechaRegistro":
            if (!valor.trim()) {
                validacion.errores.fechaRegistro = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length !== 4) {
                validacion.errores.fechaRegistro = 'Revisa que el año sea 4 dígitos';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroEmision":
            if (!valor.trim()) {
                validacion.errores.numeroEmision = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length !== 2) {
                validacion.errores.numeroEmision = 'Formato de Emisión no válido, 2 dígitos';
            } else {
                validacion.correcto = true;
            }
            break;
        case "cic":
            if (!valor.trim() || valor === "") {
                validacion.errores.cic = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length < 8 || valor.length > 13) {
                validacion.errores.cic = 'Formato de CIC/OCR no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "ocr":
            if (!valor.trim() || valor === "") {
                validacion.errores.ocr = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length < 12 || valor.length > 13) {
                validacion.errores.ocr = 'Formato de OCR no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "curp":
            if (!valor.trim()) {
                validacion.errores.curp = 'Debes ingresar un valor válido';
            } else if (valor.length < 18 || !esNumLetras(valor) || valor.length > 18 || !validarCurp(valor)) {
                validacion.errores.curp = 'Formato de CURP no válido';
            } else if (valor.length === 18 && !validarCurp(valor)) {
                validacion.errores.curp = 'Formato de CURP no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "vigencia":
            if (!valor.trim()) {
                validacion.errores.vigencia = 'Debes ingresar un valor válido';
            } else if ((valor.length !== 4)) {
                validacion.errores.vigencia = 'Revisa que el año sea 4 dígitos';
            } else if (!esNumeros(valor)) {
                validacion.errores.vigencia = 'Formato de vigencia no válido';
            }
            /* let today = new Date();
            let year = today.getFullYear(); 
            else if (Number(valor) < year) {
                validacion.errores.vigencia = 'Vigencia de identificación no válida';
            }  */
            else {
                validacion.correcto = true;
            }
            break;
        case "vigenciaPasaporte":
            if (valor === 'PERMANENTE') {
                validacion.correcto = true
            } else if (!valor.trim()) {
                validacion.errores.vigenciaPasaporte = 'Debes ingresar un valor válido';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.correcto = true;
            } else {
                validacion.errores.vigenciaPasaporte = 'Revisa el formato dd/mm/aaaa';
            }
            break;
        case "dni":
            if (!valor.trim()) {
                validacion.errores.dni = 'Debes ingresar un valor válido';
            } else if (valor.length < 3 || !esNumLetras(valor)) {
                validacion.errores.dni = 'Formato de DNI no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "codigoPostal":
            if (!valor.trim()) {
                validacion.errores.codigoPostal = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor)) {
                validacion.errores.codigoPostal = 'Código postal no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "pais":
            if (!valor.trim()) {
                validacion.errores.pais = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "estado":
            if (!valor.trim()) {
                validacion.errores.estado = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "delegacion":
            if (!valor.trim()) {
                validacion.errores.delegacion = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "colonia":
            if (!valor.trim()) {
                validacion.errores.colonia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "otraColonia":
            if (!valor.trim()) {
                validacion.errores.otraColonia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "calle":
            if (!valor.trim()) {
                validacion.errores.calle = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroExterior":
            if (!valor.trim()) {
                validacion.errores.numeroExterior = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;

        // Campos de Formulario IPN
        case "full_name":
            if (!valor.trim()) {
                validacion.errores.full_name = 'Debes ingresar un valor válido';
            } else if (valor.length < 10 || !esSoloLetras(valor)) {
                validacion.errores.full_name = 'Nombre no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "unit":
            if (!valor.trim()) {
                validacion.errores.unit = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "id":
            if (!valor.trim()) {
                validacion.errores.id = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "expireDate":
            if (!valor.trim()) {
                validacion.errores.expireDate = 'Debes ingresar un valor válido';
            } else if ((valor.length !== 4)) {
                validacion.errores.expireDate = 'Revisa que el año sea 4 dígitos';
            } else if (!esNumeros(valor)) {
                validacion.errores.expireDate = 'Formato de vigencia no válido';
            }
            else {
                validacion.correcto = true;
            }
            break;
        case "expireDateFull":
            if (!valor.trim()) {
                validacion.errores.expireDateFull = 'Debes ingresar un valor válido';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.correcto = true;
            } else {
                validacion.errores.expireDateFull = 'Revisa el formato dd/mm/aaaa';
            }
            break;
        case "numeroInterior":
            validacion.correcto = true;
            break;
        default:
            validacion.correcto = true
            break;
    }
    //console.log("validacion",validacion);
    return validacion;
};

export const evento = (paso, tipo, data, finished) => {
    const dato = JSON.stringify(data);
    let fecha = "";
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let min = date.getMinutes();
    let seconds = date.getSeconds();
    fecha = `${year}-${(month > 9) ? month : `0${month}`}-${(day > 9) ? day : `0${day}`}_${(hour > 9) ? hour : `0${hour}`}:${(min > 9) ? min : `0${min}`}:${(seconds > 9) ? seconds : `0${seconds}`}`;
    let uuidUser = localStorage.getItem("uuidUser");
    let uuidTrx = localStorage.getItem("uuidTrx");
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante");
    let uuidOtorgante = localStorage.getItem("uuidOtorgante");
    let objeto = {
        uuid_clt: uuidUser,
        uuid_trx: uuidTrx,
        uuid_oto: uuidOtorgante,
        apikey: apikeyOtorgante,
        paso: paso,
        tipo: tipo,
        dato: dato,
        finished: finished,
        time_stamp: fecha
    }
    return enviarEvento(objeto).then(response => {
        //console.log('RESPONSE', response);
        return
    }).catch(error => console.log('ERROR', error));
}


export const endpointHolograma = (file, isIOS) => {
    let tipo = '';
    let informacion = {};
    enviarHologramaIdentificacion(file, isIOS).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            tipo = 'Success';
            evento('Captura Holograma', tipo, informacion, true);
        }
    }).catch(error => {
        console.log('ERROR', error);
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Captura Holograma', tipo, informacion, false);
    })
}

export const getX = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424 || innerWidth < 424) {
        return 2;
    } else {
        return 2;
    }
}

export const getY = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth > 424) {
        return 24;
    } else if (innerWidth === 424) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 29;
        }
    } else if (innerWidth >= 412) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 32;
        }
    } else if (innerWidth >= 360) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 27;
        }
    } else {
        return 27;
    }
}

export const getWidth = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424) {
        return 97;
    } else if (innerWidth >= 412) {
        return 96;
    } else if (innerWidth >= 360) {
        return 96;
    } else {
        return 96;
    }
}

export const getHeight = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424) {
        return 45;
    } else if (innerWidth >= 412) {
        return 38;
    } else if (innerWidth >= 360) {
        if (innerHeight <= 640) {
            return 45;
        } else if (innerHeight <= 657) {
            return 37;
        } else if (innerHeight <= 664) {
            return 45;
        } else {
            return 35;
        }
    } else {
        return 35;
    }
}

export const flowIncompleted = async (objetoInformacion, flag) => {
    let tipo = '';
    let informacion = {};
    let objeto = { createScore: true }
    let scoreObject = JSON.parse(localStorage.getItem('scoreObject'))
    if (scoreObject != null) {
        objeto = { ...objeto, ...scoreObject }
    }
    return finalUpdateScore(objeto).then(async ({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            await evento('Actualizar Score', 'Success', informacion, true);
        }
        await setFlagStatus(objetoInformacion, flag);
    }).catch(async error => {
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Actualizar Score', tipo, informacion, false);
        await setFlagStatus(objetoInformacion, flag);
    });
}

export const setFlagStatus = async (objetoInformacion, flag) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let dataEvent = null;
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));

        let telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        let correo = localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        let fullName = dataUser ? obtenerValorUser(dataUser, "nombreCompleto", "") : '';
        let tipoIdentificacion = localStorage.getItem("typeID");

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag,
            'typeID': tipoIdentificacion ? tipoIdentificacion : '',
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        dataEvent = JSON.parse(objetoInformacion.event)

        return actualizarEstado(objetoFinal).then(async ({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = dataEvent.status;
                informacion.dataLifeCycle = objetoFinal;
                tipo = 'Update Cycle';
                await evento(dataEvent.step, tipo, informacion, true);
                localStorage.setItem("completado", "true");
            }
        }).catch(async (error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            await evento(dataEvent.step, tipo, informacion, false);
            localStorage.setItem("completado", "true");
        }).finally(() =>{
            if (window.opener) {
                window.opener.postMessage(JSON.stringify({
                    step: objetoInformacion.step,
                    status: 'INCOMPLETED',
                    type: "PROCESS",
                    error_text: 'Finalizado por reintentos',
                }), localStorage.getItem("externalReference"))
            }
        });
        
    } else {
        return
    }
}

const initUpdateScore = () => {
    let tipo = '';
    let informacion = {};
    actualizarScoreInicioFlujo().then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            tipo = 'Success';
            evento('Actualizar Score', tipo, informacion, true);
        }
    }).catch(error => {
        console.log('ERROR', error);
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Actualizar Score', tipo, informacion, false);
    })
}

export const sendZip = async (tipoEnvio, bandera) => {
    let dataOtorganteLS = localStorage.getItem("dataOtorgante");
    let semaforo = obtenerValorConfig(JSON.parse(dataOtorganteLS), "SEND_ZIP_TRUNCOS", 'false');
    if (semaforo === 'true') {
        let dataJSONr = await convertdataJSON();
        let tipo = '';
        let informacion = {};
        dataJSONr.tipo_envio = tipoEnvio;
        dataJSONr.semaforo = bandera;
        return enviarZipAmarillos(dataJSONr).then((response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Envío Zip', 'Success', informacion, true);
            }
        }).catch((error) => {
            if (error.response) {
                let { data: { message_client }, status } = error.response
                informacion.mensaje = message_client;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Envío Zip', tipo, informacion, false);
        })
    } else {
        return
    }
}

const convertdataJSON = () => {
    let dataOtorgante = null;
    let uuid = localStorage.getItem("uuidUser");
    let dataOtorganteLS = localStorage.getItem("dataOtorgante")
    if (dataOtorganteLS) {
        dataOtorgante = JSON.parse(dataOtorganteLS);
    }
    let correo = localStorage.getItem("correoElectronico") || '';
    var fecha = ""
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (month < 10) {
        fecha = `${day}0${month}${year}`
    } else {
        fecha = `${day}${month}${year}`
    }
    var jsonObj = {};
    jsonObj.uuidUser = uuid;
    let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
    partner = partner.replace(" ", "_");
    if (partner !== "") {
        partner = partner + "_"
    }
    let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
    name_otorgante = name_otorgante.replace(" ", "_");
    let uuidC = partner + name_otorgante + "_" + fecha + "_" + uuid.split("-")[0];
    jsonObj.custom_uuid = uuidC;
    jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
    jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
    jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || {};
    jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
    jsonObj.email = correo;
    return jsonObj;
}

export const sendEventClick = (paso, description, objectInformation = {}) => {
    let objeto = { description: description };
    let objetoEvento = {
        ...objeto,
        ...objectInformation
    }
    evento(paso, 'Click', objetoEvento, true);
}

export const sendImage = async (imagen, modelo, nombre, tipoImagen, lado) => {
    let tipo = '';
    let informacion = {};
    enviarImagenEvaluar(imagen, modelo, nombre, lado).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            evento(tipoImagen, 'Success', informacion, true);
        }
    }).catch((error) => {
        if (error.response) {
            let { data: { message_client }, status } = error.response
            informacion.mensaje = message_client;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento(tipoImagen, tipo, informacion, false);
    })
}

export const validarOtorganteCDC = () => {
    let otorganteCDC = false;
    const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
    const uuidOtorgante = localStorage.getItem("uuidOtorgante");
    const enable_send_mail = obtenerValorConfig(dataOtorgante, 'ENABLE_SEND_MAIL', "");
    const validacionCorreoCDC = (enable_send_mail && (enable_send_mail.toLowerCase() === 'false')) ? true : false;

    if (uuidOtorgante === 'e89ba843-66e4-4f8f-92ae-a148fb751dba' || validacionCorreoCDC) {
        otorganteCDC = true;
    }
    return otorganteCDC;
}

export const sendZipFlag = async (tipoEnvio, bandera, isIOS) => {
    // console.log("sendZipFlag");
    let objeto = {};
    let tipo = '';
    let informacion = {};
    let uuid = '';
    let uuidTrx = '';
    let dataUser = [];
    let dataOtorgante = [];
    let uuidUser = localStorage.getItem("uuidUser")
    if (uuidUser) {
        uuid = uuidUser;
    }
    let uuidTrxLS = localStorage.getItem("uuidTrx")
    if (uuidTrxLS) {
        uuidTrx = uuidTrxLS;
    }
    let dataUserLS = localStorage.getItem("data_user")
    if (dataUserLS) {
        dataUser = JSON.parse(dataUserLS);
    }
    let dataOtorganteLS = localStorage.getItem("dataOtorgante")
    if (dataOtorganteLS) {
        dataOtorgante = JSON.parse(dataOtorganteLS);
    }
    objeto.tipo_envio = tipoEnvio;
    objeto.semaforo = bandera;
    objeto.uuidUser = uuid;
    objeto.name = obtenerValorUser(dataUser, "nombreCompleto");
    objeto.email = '';
    objeto.fNac = obtenerValorUser(dataUser, "nacimiento");
    objeto.curp = (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp");
    objeto.vigencia = obtenerValorUser(dataUser, "vigencia");
    objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
    objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
    objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
    let fecha = ""
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (month < 10) {
        fecha = `${day}0${month}${year}`
    } else {
        fecha = `${day}${month}${year}`
    }
    let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
    partner = partner.replace(" ", "_");
    if (partner !== "") {
        partner = partner + "_"
    }
    let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
    name_otorgante = name_otorgante.replace(" ", "_");
    objeto.custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
    objeto.originOS = isIOS ? 'ios' : 'android';
    objeto.coincidencia = "Requiere validación";
    enviarCorreoAceptacion(objeto).then((response) => {
        if (response.status === 200) {
            informacion.status = response.status;
            informacion.payload = response.data.payload;
            evento('Envío Zip Bandera', 'Success', informacion, true);
        }
    }).catch((error) => {
        if (error.response) {
            let { data: { message_client }, status } = error.response
            informacion.mensaje = message_client;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Envío Zip Bandera', tipo, informacion, false);
    })
}

/* Video token pdf */
export const enviarVideoTokenPdf = (facefile, Acreated, Aexpire, isIOS, service_call) => {
    let so = isIOS ? 'ios' : 'android';
    let data = new FormData();
    let headers = {};
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';

    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    data.append("originOS", so);
    data.append("service_call", service_call);
    data.append("videotoken", facefile, VIDEO_OUTPUT_FILE);

    headers = {
        'Content-Type': 'multipart/form-data',
        'X-Consumer-Custom-ID': localStorage.getItem("uuidOtorgante"),
        'transaccion': localStorage.getItem("uuidTrx"),
        'cliente': localStorage.getItem("uuidUser"),
        'apikey': localStorage.getItem("apikeyOtorgante"),
    }

    return enviarVideoRostroPDF(headers, data);
}

/*--- Generación de archivop ZIP de NOM 151 ---*/
export const generateZip = async (tipoEnvio, bandera, isIOS) => {
    let objeto = {}
    let dataOtorgante = [];
    let dataUser = [];
    let uuid = '';
    let uuidTrx = '';
    let tipo = '';
    let nombreEvento = '';
    let informacion = {};

    let dataOtorganteLS = localStorage.getItem("dataOtorgante");
    if (dataOtorganteLS) {
        dataOtorgante = JSON.parse(dataOtorganteLS);
    }
    let uuidUser = localStorage.getItem("uuidUser");
    if (uuidUser) {
        uuid = uuidUser;
    }
    let uuidTrxLS = localStorage.getItem("uuidTrx");
    if (uuidTrxLS) {
        uuidTrx = uuidTrxLS;
    }
    let dataUserLS = localStorage.getItem("data_user")
    if (dataUserLS) {
        dataUser = JSON.parse(dataUserLS);
    }
    let sendZipTruncos = obtenerValorConfig(dataOtorgante, "SEND_ZIP_TRUNCOS", 'false');
    let sendZipSemaforo = obtenerValorConfig(dataOtorgante, "SEND_ZIP_SEMAFORO", 'false');
    let fecha = ""
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (month < 10) {
        fecha = `${day}0${month}${year}`
    } else {
        fecha = `${day}${month}${year}`
    }
    let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
    partner = partner.replace(" ", "_");
    if (partner !== "") {
        partner = partner + "_"
    }
    let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
    name_otorgante = name_otorgante.replace(" ", "_");

    objeto.tipo_envio = tipoEnvio;
    objeto.semaforo = bandera;
    objeto.uuidUser = uuid;
    objeto.originOS = isIOS ? 'ios' : 'android';
    objeto.custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
    // if (tipoEnvio === 'COMPLETO') {
    //     objeto.estado_flujo = "COMPLETADO"
    // } else {
    //     objeto.estado_flujo = "CANCELADO"
    // }

    switch (bandera) {
        case 'trunco':
            nombreEvento = 'Generacíon de Nom 151 trunco';
            objeto.ocrFront = null;
            objeto.ocrBack = null;
            objeto.data_user = null;
            break;
        default:
            nombreEvento = 'Generacíon de Nom 151 con Bandera';
            objeto.name = obtenerValorUser(dataUser, "nombreCompleto");
            objeto.email = '';
            objeto.fNac = obtenerValorUser(dataUser, "nacimiento");
            objeto.curp = (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp");
            objeto.vigencia = obtenerValorUser(dataUser, "vigencia");
            objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
            objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
            objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
            objeto.coincidencia = "Requiere validación";
            break;
    }
    if (((tipoEnvio === 'TRUNCOS') && (sendZipTruncos.toLowerCase() === 'true')) || ((tipoEnvio === 'SEMAFORO') && (sendZipSemaforo.toLowerCase() === 'true'))) {
        return generacionNom151(objeto).then((response) => {
            if (response.status === 201) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento(nombreEvento, 'Success', informacion, true);
            }
        }).catch((error) => {
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento(nombreEvento, tipo, informacion, false);
        })
    } else
        return
}

/* Validación RENAPO */
const validarCurp = (curp) => {
    let result = true
    const expreg = /^[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;
    //var result = input.match(expreg);
    if (!curp.match(expreg))
        result = false
    //console.log("RESULT",result);
    return result
}

export const validarRenapo = async (jobID) => {
    if (!cancelarPeticionRENAPO) {
        if (contadorRENAPO < 15) {
            let tipo = '';
            let informacion = {};
            obtenerResultadoRenapo(jobID).then(async ({ data: { payload: { item } }, status, data: { payload } }) => {
                if (status === 200) {
                    if (item && item.length > 0) {
                        informacion.status = status;
                        informacion.payload = payload;
                        //localStorage.setItem("validacionRENAPO", true);
                        let responseItem = item[0];
                        if (responseItem.status.trim() === 'VALIDADO') {
                            statusData('validacion_renapo', { validacion: true, status: responseItem.status.trim() });
                        } else if (responseItem.status.trim() === 'INVALIDO') {
                            statusData('validacion_renapo', { validacion: false, status: responseItem.status.trim() });
                        } else {
                            statusData('validacion_renapo', { validacion: false, status: responseItem.status.trim() });
                            setTimeout(() => {
                                validarRenapo(jobID);
                                contadorRENAPO++;
                            }, 1000);
                        }
                    } else {
                        statusData('validacion_renapo', { validacion: false, status: 'PROCESANDO' });
                        setTimeout(() => {
                            validarRenapo(jobID);
                            contadorRENAPO++;
                        }, 1000);
                    }
                    evento('Validación RENAPO', 'Success', informacion, true);
                }
            }).catch(error => {
                if (error.response) {
                    let { data, status } = error.response
                    informacion.data = data;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Validación RENAPO', tipo, informacion, false);
                setTimeout(() => {
                    if (error.toString() === 'Cancel') {
                        statusData('validacion_renapo', { validacion: false, status: 'CANCELADO' });
                        contadorRENAPO = 0;
                    } else {
                        statusData('validacion_renapo', { validacion: false, status: 'ERROR VALIDACION' });
                        validarRenapo(jobID);
                        contadorRENAPO++;
                    }
                }, 1000);
            });
        } else {
            statusData('validacion_renapo', { validacion: false, status: 'ERROR RENAPO' });
            contadorRENAPO = 0;
        }
    } else {
        statusData('validacion_renapo', { validacion: false, status: 'CANCELADO' });
        cancelarPeticionRENAPO = false;
        contadorRENAPO = 0;
    }
}

export const encolarPeticionRenapo = (curp) => {
    if (!cancelarPeticionRENAPO) {
        if (contadorRENAPO < 10) {
            let tipo = '';
            let informacion = {};
            return enviarRenapo(curp).then(({ status, data: { payload }, data: { payload: { job_id } } }) => {
                informacion.status = status;
                informacion.payload = payload;
                evento('Encolar petición RENAPO', 'Success', informacion, true);
                statusData('validacion_renapo', { validacion: false, status: 'EN PROCESO' });
                contadorRENAPO = 0;
                validarRenapo(job_id);
            }).catch(error => {
                if (error.response) {
                    let { data: { message_client }, status } = error.response
                    informacion.mensaje = message_client;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Encolar petición RENAPO', tipo, informacion, false);
                if (error.toString() === 'Cancel') {
                    statusData('validacion_renapo', { validacion: false, status: 'CANCELADO' });
                    contadorRENAPO = 0;
                } else {
                    statusData('validacion_renapo', { validacion: false, status: 'ERROR ENCOLAMIENTO' });
                    encolarPeticionRenapo(curp);
                    contadorRENAPO++;
                }
            })
        } else {
            statusData('validacion_renapo', { validacion: false, status: 'ERROR RENAPO' });
            contadorRENAPO = 0;
            return;
        }
    } else {
        statusData('validacion_renapo', { validacion: false, status: 'CANCELADO' });
        cancelarPeticionRENAPO = false;
        contadorRENAPO = 0;
    }

}

/* Validación INE */

export const validarINE = async jobID => {
    if (!cancelarPeticionINE) {
        if (contadorINE < 15) {
            let tipo = '';
            let informacion = {};
            obtenerResultadoINE(jobID).then(async ({ data: { payload: { message } }, status, data: { payload } }) => {
                if (status === 200) {
                    if (message && message.length > 0) {
                        informacion.status = status;
                        informacion.payload = payload;
                        let responseItem = message[0];
                        if (responseItem.estatus.trim() === 'VALIDADO') {
                            statusData('validacion_ine', { validacion: true, status: responseItem.estatus.trim() });
                            contadorINE = 0;
                        } else if (responseItem.estatus.trim() === 'INVALIDO') {
                            statusData('validacion_ine', { validacion: false, status: responseItem.estatus.trim() });
                        } else {
                            statusData('validacion_ine', { validacion: false, status: responseItem.estatus.trim() });
                            setTimeout(() => {
                                validarINE(jobID);
                                contadorINE++;
                            }, 1000);
                        }
                    } else {
                        statusData('validacion_ine', { validacion: false, status: 'PROCESANDO' });
                        setTimeout(() => {
                            validarINE(jobID);
                            contadorINE++;
                        }, 1000);
                    }
                    evento('Validación INE', 'Success', informacion, true);
                }
            }).catch(error => {
                if (error.response) {
                    let { data, status } = error.response
                    informacion.mensaje = data;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Validación INE', tipo, informacion, false);
                setTimeout(() => {
                    if (error.toString() === 'Cancel') {
                        statusData('validacion_ine', { validacion: false, status: 'CANCELADO' });
                        contadorINE = 0;
                    } else {
                        statusData('validacion_ine', { validacion: false, status: 'ERROR VALIDACION' });
                        validarINE(jobID);
                        contadorINE++;
                    }
                }, 1000);
            });
        } else {
            statusData('validacion_ine', { validacion: false, status: 'ERROR INE' });
            contadorINE = 0;
        }
    } else {
        statusData('validacion_ine', { validacion: false, status: 'CANCELADO' });
        cancelarPeticionINE = false;
        contadorRENAPO = 0;
    }
}

export const encolarPeticionINE = (informacionIne) => {
    if (!cancelarPeticionINE) {
        if (contadorINE < 10) {
            let tipo = '';
            let informacion = {};
            let ocrFront = null
            let dataOCRFrontLS = localStorage.getItem("ocrFront");
            if (dataOCRFrontLS) ocrFront = JSON.parse(dataOCRFrontLS);
            let modeloIdentificacion = 'A';
            if (ocrFront.identificacionElectoral) {
                return enviarIne(informacionIne.ocr, informacionIne.idCiudadano, informacionIne.claveElector, informacionIne.numeroEmision, modeloIdentificacion).then(
                    ({ status, data: { payload }, data: { payload: { message } } }) => {
                        informacion.status = status;
                        informacion.payload = payload;
                        evento('Encolar petición INE', tipo, informacion, true);
                        statusData('validacion_ine', { validacion: false, status: 'EN PROCESO' });
                        contadorINE = 0;
                        validarINE(message.data);
                    }).catch(error => {
                        if (error.response) {
                            let { data, status } = error.response
                            informacion.data = data;
                            informacion.status = status;
                            tipo = 'Error';
                        } else {
                            informacion.error = error.toString();
                            tipo = 'Exception';
                        }
                        evento('Encolar petición INE', tipo, informacion, false);
                        if (error.toString() === 'Cancel') {
                            statusData('validacion_ine', { validacion: false, status: 'CANCELADO' });
                            contadorINE = 0;
                        } else {
                            statusData('validacion_ine', { validacion: false, status: 'ERROR ENCOLAMIENTO' });
                            encolarPeticionINE(informacionIne);
                            contadorINE++;
                        }
                    })
            } else {
                return
            }
        } else {
            statusData('validacion_renapo', { validacion: false, status: 'ERROR INE' });
            contadorINE = 0;
        }
    } else {
        statusData('validacion_ine', { validacion: false, status: 'CANCELADO' });
        cancelarPeticionINE = false;
        contadorRENAPO = 0;
    }

}

export const cancelarINE = () => cancelarPeticionINE = true;

export const cancelarRENAPO = () => cancelarPeticionRENAPO = true;

export const setCancelarINE = () => cancelarPeticionINE = false;

export const setCancelarRENAPO = () => cancelarPeticionRENAPO = false;

export const validarRedireccionamiento = (dataOtorgante) => {
    let validacion = false;
    const enable_redirect = obtenerValorConfig(dataOtorgante, 'ENABLE_REDIRECT', '');
    const validacionRedirect = (enable_redirect && (enable_redirect.toLowerCase() === 'true')) ? true : false;

    if (validacionRedirect) {
        validacion = true;
    }
    return validacion;
}

export const enviarImagenVideotoken = (image, isIOS, service_call) => {
    let originOS = isIOS ? 'ios' : 'android';
    let data = new FormData();
    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", null);
    data.append("expire", null);
    data.append("originOS", originOS);
    data.append("service_call", service_call);
    data.append("disable_video_token", true);
    const ocrFront = JSON.parse(localStorage.getItem("ocrFront"))
    let passFlag = "false"
    if (ocrFront) {
        if (ocrFront.identificacionPasaporte) {
            passFlag = "true"
        }
    }
    data.append("identificacion_pasaporte", passFlag);
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'selfie.png';
    data.append("videotoken", image, VIDEO_OUTPUT_FILE);

    return nuevoVideotoekn(data)

}

const msjError = "No hemos logrado validar tu captura, por favor sigue las instrucciones e intenta nuevamente"
const msjLuz = "Por favor busca una posición con menos luz directa en tu rostro al momento de la captura."
const msjObscuridad = "Por favor busca un lugar con más luz al momento de la captura."
const msjCubrebocas = "Parece que algún objeto obstruye tu rostro, por favor retíralo y captura nuevamente."
const msjRostro = "Por favor busca una posición con menos luz directa y centra tu rostro en el área marcada al momento de la captura."
const msjMultiple = "Parece que hay más de un rostro en la toma, asegúrate que no haya nadie mas al momento de la captura."
const msjBorrosa = "Por favor asegurate que tu cámara esta limpia y mantén firme tu dispositivo para evitar distorsiones en la imagen."
const msjFrase = "No logramos entender la frase, por favor lee FUERTE y CLARO en un lugar silencioso e intenta nuevamente."
const msjFrase2 = "No logramos entender la frase, por favor lee FUERTE y CLARO en un lugar silencioso e intenta nuevamente."
const msjErrorEmbedings = "Lo sentimos detectamos inconsistencias en tu captura, por favor reinicia el proceso."

export const mapearMensajeError = (mensaje) => {
    let eMessage = null
    switch (mensaje) {
        case 'No se encontro rostro':
            eMessage = msjRostro
            break;
        case 'Se detecto cubrebocas':
            eMessage = msjCubrebocas
            break;
        case 'Condiciones de luz no adecuadas':
            eMessage = msjLuz
            break;
        case 'Imagen borrosa':
            eMessage = msjBorrosa
            break;
        case 'Se detectaron multiples caras':
            eMessage = msjMultiple
            break;
        case 'No logramos entender la frase, intente nuevamente':
            eMessage = msjFrase
            break;
        case 'Falta de luz':
            eMessage = msjObscuridad
            break;
        case 'Error frase':
            eMessage = msjFrase2
            break;
        case 'No hay coincidencia intente nuevamente':
            eMessage = msjErrorEmbedings;
            break;
        case 'Lo sentimos no hay coincidencia entre tu selfie registrada y la persona actual':
            eMessage = mensaje
            break;
        case 'Spoof detected':
            eMessage = msjError
            break;
        default:
            eMessage = msjRostro
            break;
    }
    return eMessage
}

export const mapearRespuesta = (status, data) => {
    let objetoRespuesta = {};
    objetoRespuesta.status = status;
    objetoRespuesta.data = data;
    return objetoRespuesta;
}

export const mapearError = (error) => {
    let objetoError = {};
    let tipoError = '';
    if (error.response) {
        const { data, status } = error.response;
        objetoError.status = status;
        objetoError.data = data;
        objetoError.tipo = 'RESPONSE';
        tipoError = 'Error';
    } else if (error.request) {
        objetoError.error = error.message ? error.message : error.toString();
        objetoError.tipo = 'REQUEST';
        tipoError = 'Error';
    } else {
        objetoError.error = error.message;
        objetoError.tipo = 'EXCEPTION';
        tipoError = 'Exception';
    }
    return { objetoError, tipoError };
}

export const validarParametroOtorgante = (dataOtorgante, parametro) => {
    let validacion = false;
    const parametroOtorgante = obtenerValorConfig(dataOtorgante, parametro, '');
    const validacionParametro = (parametroOtorgante && (parametroOtorgante.toLowerCase() === 'true')) ? true : false;
    if (validacionParametro) {
        validacion = true;
    }
    return validacion;
}


//LIVENESS IFRAME
export const setLocalStorageResultFace = (result) => {
    let face = {}
    //console.log("responseWRT: ", result);
    if (result.match) {
        face = {
            match: result.match,
            /* gender: (result.faceComparison.payload.Query[0].gender) === "male" ? "Masculino" : "Femenino",
            age: result.faceComparison.payload.Query[0].age.toFixed(0),
            expressions: result.faceComparison.payload.Query[0].expressions, */
            liveness: result.liveness
        }
    } else {
        face = {
            match: false,
            gender: "Indeterminado",
            age: 0,
            expressions: {
                happy: 0.0
            },
            liveness: "No superada"
        };
    }//liveness
    localStorage.setItem("face", JSON.stringify(face));
};

export const convertBase64ToBlob = (base64) => {
    // const sliceSize = 512
    // const byteCharacters = atob(base64);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    return fetch(base64).then(res => res.blob())
}

export const getNextStepIdentification = (dataOtorgante) => {
    let step = '/video_token';
    if (validarParametroOtorgante(dataOtorgante, 'DISABLE_LIVENESS')) {
        step = '/preparacion_liveness'
    }

    if (validarParametroOtorgante(dataOtorgante, 'DISABLE_LIVENESS') === false) {
        if (validarParametroOtorgante(dataOtorgante, 'DISABLE_VIDEO_TOKEN')) {
            step = '/selfie'
        }
    }
    return step;
}

export const validar_correo = (correo) => {
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(correo)){
        // var validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        // if( validEmail.test(correo) ) 
        return true;
    }
    return false;
}