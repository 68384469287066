import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { version } from 'recordrtc';

const Header = (props) => {
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [hayCookies] = useState(navigator.cookieEnabled);
    const [pathname, setpathname] = useState('');
    const [version, setVersion] = useState(false);

    /* Refs */
    const divRef = useRef(null)
    const imgRef = useRef(null)

    useEffect(() => {
        let host = window.location.pathname;
        setpathname(host)

        if (hayCookies) {
            let dataOtorganteLS = localStorage.getItem("dataOtorgante")
            if (dataOtorganteLS != null) {
                setDataOtorgante(JSON.parse(dataOtorganteLS))
                configStyle(JSON.parse(dataOtorganteLS))
            }
        }
        // //console.log(pathname);
    }, []);

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    /* useEffect(() => {
        if(imgRef.current){
            console.log('IMG', imgRef.current)
            console.log('WIDTH', imgRef.current.width)
            const valorPadding = (window.innerWidth - imgRef.current.width)
            console.log('VALOR', valorPadding)
        }
    }, [imgRef.current]); */


    const imageOnLoad = () => {
        if (imgRef.current) {
            const valorPadding = Math.round((window.innerWidth - imgRef.current.width) / 2)
            if(divRef.current){
                divRef.current.style.paddingLeft = `${valorPadding}px`
                divRef.current.style.paddingRight = `${valorPadding}px`
                divRef.current.removeAttribute('hidden')
            }
        }
    }

    const showVersion = () => {
        setVersion(true)
    }

    return (
        <Fragment>
            <div id="head_container">
                <img id="imagenLogo" className="comp_logo animate__animated animate__slideInDown" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") !== "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" ref={imgRef} onLoad={imageOnLoad} onClick={showVersion} />
                {/* <div ref={divRef} className='div-version animate__animated animate__fadeIn' hidden>
                    <p>ver.2.3.10</p>
                </div> */}
                {
                    (pathname === "/i2MopevgEp2HSmOppDOR3MHUzT4SEZOi") ?
                        <a className="help_btn animate__animated animate__fadeIn animate__delay-1s"><img src={process.env.PUBLIC_URL + '/images/btn_circle_help_c2all.svg'} alt="" onClick={e => props.show()} /></a>
                        :
                        ""
                }
            </div>
            {(version) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><b>Versión de la aplicación</b></h5>
                            </div>
                            <div className="modal-body">
                                <p>La version de la aplicación actual es: <b>V_2.4.1</b></p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setVersion(false)}} className="btn btn-raised btn-primary main_bg_color" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
        </Fragment>
    );
}

export default Header;