import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/headerNA";
import { obtenerValorConfig, obtenerValorUser } from '../services/configStyle'
import { configStyle } from '../services/configStyle'
import Footer from "../components/footer";
import { obtenerMaps, actualizarScore, obtenerCP } from '../services/api';
import { status, statusData, agregarInfo, evento, validarCampo, validarValoresFormulario, validarParametroOtorgante, getNextStepIdentification, hayExcepcion } from '../services/data';
import Ayuda from './ayuda';

let IdentificacionEdit = (props) => {
    const history = useHistory();
    const [dataUser, setDataUser] = React.useState([])
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [codigoPostal, setCodigoPostal] = useState("")
    const [focusCodigoPostal, setFocusCodigoPostal] = useState(false)
    const [focusColonia, setFocusColonia] = useState(false)
    const [focusCalle, setFocusCalle] = useState(false)
    const [focusNumeroExterior, setFocusNumeroExterior] = useState(false)
    //const [Gps, setGps] = useState('[19.4323007,-99.2708557]')
    const [inputActive, setInputActive] = useState(false)
    const [databack, setDataback] = useState(false)
    const [cambioCP, setCambioCP] = useState(false)
    const [otro, setOtro] = useState(false)
    const [optionsSelect, setoptionsSelect] = useState([]);
    // const [errorUbicacion, setErrorUbicacion] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [focusNumeroInterior, setFocusNumeroInterior] = useState(false);
    const [inputsValue, setInputsValue] = useState({
        curp: '',
        documentoNacionalMexico: '',
        expireDate: '',
        side: '',
        type: '',
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [continuar, setContinuar] = useState(false)
    const [showFooter, setShowFooter] = useState(true)
    const [focusCurp, setFocusCurp] = useState(false)
    const [esVigente, setEsVigente] = useState(true)
    const [focusDocumentoNacionalMexico, setFocusDocumentoNacionalMexico] = useState(false)
    const [focusExpireDate, setFocusExpireDate] = useState(false)
    const [enableCurp, setEnableCurp] = useState(true)
    const [enableDocumentoNacionalMexico, setEnableDocumentoNacionalMexico] = useState(true)
    const [enableExpireDate, setEnableExpireDate] = useState(true)
    const [enableSide, setEnableSide] = useState(true)
    const [enableType, setEnableType] = useState(true)



    useEffect(() => {
        let dataOtorganteLS = "", dataUserLS = "", dataOCRFrontLS = "";
        dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        dataOCRFrontLS = localStorage.getItem("ocrBack")
        if (dataOCRFrontLS != null) {
            const ocrFront = JSON.parse(dataOCRFrontLS)
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrFront.curp).trim() }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['documentoNacionalMexico']: ocrFront.documentoNacionalMexico || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['expireDate']: ocrFront.expireDate || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['side']: ocrFront.side || '' }));
            setInputsValue(inputsValue => ({ ...inputsValue, ['type']: ocrFront.type || '' }));
            setDataback(true)
            if (ocrFront.type === "officer") {
                setEnableCurp(true)
                setEnableDocumentoNacionalMexico(true)
                setEnableExpireDate(true)
                setEnableSide(false)
                setEnableType(true)
            }
            else if (ocrFront.type === "student")  {
                setEnableCurp(true)
                setEnableDocumentoNacionalMexico(true)
                setEnableExpireDate(true)
                setEnableSide(false)
                setEnableType(true)
            }
            else if (ocrFront.type === "teacher")  {
                setEnableCurp(true)
                setEnableDocumentoNacionalMexico(true)
                setEnableExpireDate(true)
                setEnableSide(false)
                setEnableType(true)
            }
        }
        status("datos_direccion_ocr_page", "Datos de direccion OCR")
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser])

    const actualizarDatos = (event) => {
        // if (Gps !== '[19.4323007,-99.2708557]' && calle.trim()) {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        let tipo = '';
        let informacion = {};
        if (continuar) {
            // Campos Ocr back IPN
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            agregarInfo(dataUser, { description: "documentoNacionalMexico", value: inputsValue.documentoNacionalMexico });
            agregarInfo(dataUser, { description: "expireDate", value: inputsValue.expireDate });
            agregarInfo(dataUser, { description: "side", value: inputsValue.side });
            agregarInfo(dataUser, { description: "type", value: inputsValue.type });

            let infoFormulario = []
            let fechaActual = inputsValue.expireDate
            // Campos Ocr back IPN
            infoFormulario.push({ description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "documentoNacionalMexico", value: inputsValue.documentoNacionalMexico });
            infoFormulario.push({ description: "expireDate", value: inputsValue.expireDate });
            let today = new Date();
            let year = today.getFullYear();
            if (Number(fechaActual) >= year) {
                infoFormulario.push({ description: "expireDate", value: "31/12/20" + inputsValue.expireDate.substr(-2) })
                agregarInfo(dataUser, { description: "expireDate", value: "31/12/20" + inputsValue.expireDate.substr(-2) });
                fechaActual = "20" + inputsValue.expireDate.substr(-2) + "/12/31";
                let fechaV = new Date(fechaActual);
                if (fechaV >= Date.now()) {
                    console.log("Vigencia válida");
                } else {
                    console.log("Vigencia invalida");
                    setEsVigente(false)
                }
            } else {
                console.log("Vigencia invalida");
                setEsVigente(false)
            }
            infoFormulario.push({ description: "side", value: inputsValue.side });
            infoFormulario.push({ description: "type", value: inputsValue.type });

            document.getElementById("form-ocr").reset();
            //actualizarScore
            let uuid = localStorage.getItem("uuidUser");
            actualizarScore(uuid, inputsValue.curp, inputsValue.documentoNacionalMexico, inputsValue.expireDate, inputsValue.side, inputsValue.type).then((response) => {
                if (response.status === 200) {
                    //console.log(response.data);
                    informacion.status = response.status;
                    informacion.payload = response.data.payload;
                    evento('Actualizar Score', 'Success', informacion, true);
                    sendData(infoFormulario);
                    setTimeout(() => {
                        if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_2', "true") === "true") {
                            history.push('/clausula');
                        } else if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_3', "true") === "true") {
                            history.push("/autorizacion_solicitud");
                        } else {
                            /*
                            if (uuidOtorgante === "iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt" || uuidOtorgante === "77b6a48f-eaf8-4b18-8808-374eaa1a3f2f") {
                                setTimeout(() => {
                                    history.push('/finalizado');
                                }, 200);
                            } else {
                                history.push("/video_token");
                            }
                            */
                            let nextStep = getNextStepIdentification(dataOtorgante)
                            console.log("nextStep", nextStep);
                            history.push(nextStep);
                        }
                    }, 200);
                }
            }).catch((error) => {
                //console.log("algun error actualizarScore");
                if (error.response) {
                    let { data: { message }, status } = error.response
                    informacion.mensaje = message;
                    informacion.status = status;
                    tipo = 'Error';
                } else {
                    informacion.error = error.toString();
                    tipo = 'Exception';
                }
                evento('Actualizar Score', tipo, informacion, false);
                sendData(infoFormulario);
                setTimeout(() => {
                    if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_2', "true") === "true") {
                        history.push('/clausula');
                    } else if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_3', "true") === "true") {
                        history.push("/autorizacion_solicitud");
                    } else {
                        // SE COMENTÓ PARA IMPLEMENTAR VALIDACIÓN
                        //history.push("/video_token");
                        let nextStep = getNextStepIdentification(dataOtorgante)
                        console.log("nextStep catch", nextStep);
                        history.push(nextStep)
                    }
                }, 200);
            })
        } else {
            // if (Gps === '[19.4323007,-99.2708557]') {
            //     setErrorUbicacion(true);
            // }
            evento('Datos de direccion OCR', 'Click', { description: 'GUARDAR', status: 'Error' }, true);
        }
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const sendData = (infoFormulario) => {
        let jsonObj = {};
        jsonObj.infoDireccion = infoFormulario;
        statusData("datos_direccion_ocr", jsonObj)
    }

    // const geoLocalizacion = () => {
    //     let output = document.getElementById("out");


    //     if (!navigator.geolocation) {
    //         output.innerHTML = "<p>Su navegador no soporta la geolocalización</p>";
    //         return;
    //     }

    //     function success(position) {
    //         let latitude = position.coords.latitude;
    //         let longitude = position.coords.longitude;
    //         console.log("[" + latitude + "," + longitude + "]");
    //         output.innerHTML = " ";
    //         setGps("[" + latitude + "," + longitude + "]");
    //         setErrorUbicacion(false);
    //     };

    //     function error() {
    //         output.innerHTML = "No se puede encontrar su ubicación";
    //     };

    //     output.innerHTML = "<p>Localizando…</p>";

    //     navigator.geolocation.getCurrentPosition(success, error);

    //     let element = document.getElementById("check_switch");
    //     element.setAttribute("disabled", "disabled");
    // }

    const handleChange = (event) => {
        event.persist();
        let value = event.target.value.trim().toUpperCase();
        let validacion = validarCampo(event.target.value, event.target.name);
        if (event.target.name === 'curp') {
            let maxLength = 18;
            if (event.target.value.length > maxLength) {
                value = value.substr(0, maxLength);
                event.target.value = value;
            }
            validacion = validarCampo(value, event.target.name);
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }
    };

    useEffect(() => {
        let validacion = validarValoresFormulario(inputsValue);
        if (validacion.correcto) {
            setContinuar(true);
            setInputsErrors(validacion.errores);
        } else {
            setContinuar(false);
            setInputsErrors(validacion.errores);
        }
    }, [inputsValue])

    return (
        <Fragment>
            <div className="main_gradient">
                <Header ruta="identificacion_edit" show={() => setShowHelp(true)} />
                <div className="main_text_container">
                    <h1 className="animate__animated">Verifica y completa tu dirección actual</h1>
                    <p className="animate__animated">Revisa que tu dirección sea correcta, puedes <b>editarla</b> o <b>corregirla</b>.</p>
                </div>

                <div className="ocr_data_display">
                    <form id="form-ocr">
                        <div className="row_ext">
                            {/* Datos OCR BACK IPN  */}
                            { (enableCurp) ?
                                <div className="col-12">
                                <div className={["form-group", "bmd-form-group", ((focusNumeroInterior || inputsValue.curp) ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                                    <label htmlFor="curp" className="bmd-label-floating">CURP:</label>
                                    <input type="text" className="form-control none_border" id="curp" name="curp" defaultValue={inputsValue.curp} onChange={handleChange} onFocus={e => {
                                        setFocusNumeroInterior(true)
                                        setInputActive(true)
                                        setShowFooter(false)
                                    }} onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.curp === '') {
                                            setFocusNumeroInterior(false)
                                        }
                                        setInputActive(false)
                                    }} />
                                    {inputsErrors.curp && (
                                        <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                                    )}
                                </div>
                                </div> : ''
                            }
                            { (enableDocumentoNacionalMexico) ?
                                <div className="col-12">
                                <div className={["form-group", "bmd-form-group", ((focusNumeroInterior || inputsValue.documentoNacionalMexico) ? "is-focused" : ""), inputsErrors.documentoNacionalMexico && 'error'].join(" ")}>
                                    <label htmlFor="documentoNacionalMexico" className="bmd-label-floating">Documento Nacional Mexicano:</label>
                                    <input type="text" className="form-control none_border" id="documentoNacionalMexico" name="documentoNacionalMexico" defaultValue={inputsValue.documentoNacionalMexico} onChange={handleChange} onFocus={e => {
                                        setFocusNumeroInterior(true)
                                        setInputActive(true)
                                        setShowFooter(false)
                                    }} onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.documentoNacionalMexico === '') {
                                            setFocusNumeroInterior(false)
                                        }
                                        setInputActive(false)
                                    }}  maxLength="5" onInput={e => maxLengthCheck(e)} />
                                    {inputsErrors.documentoNacionalMexico && (
                                        <span id="ht-documentoNacionalMexico" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.documentoNacionalMexico}</span>
                                    )}
                                </div>
                                </div> : ''
                            }
                            { (enableExpireDate) ?
                                <div className="col-12">
                                <div className={["form-group", "bmd-form-group", ((focusNumeroInterior || inputsValue.expireDate) ? "is-focused" : ""), inputsErrors.expireDate && 'error'].join(" ")}>
                                    <label htmlFor="expireDate" className="bmd-label-floating">Vigencia:</label>
                                    <input type="text" className="form-control none_border" id="expireDate" name="expireDate" defaultValue={inputsValue.expireDate} onChange={handleChange} onFocus={e => {
                                        setFocusNumeroInterior(true)
                                        setInputActive(true)
                                        setShowFooter(false)
                                    }} onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.expireDate === '') {
                                            setFocusNumeroInterior(false)
                                        }
                                        setInputActive(false)
                                    }} />
                                    {inputsErrors.expireDate && (
                                        <span id="ht-expireDate" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.expireDate}</span>
                                    )}
                                </div>
                                </div> : ''
                            }
                            { (enableSide) ?
                                <div className="col-12">
                                <div className={["form-group", "bmd-form-group", ((focusNumeroInterior || inputsValue.side) ? "is-focused" : ""), inputsErrors.side && 'error'].join(" ")}>
                                    <label htmlFor="side" className="bmd-label-floating">Lado:</label>
                                    <input type="text" className="form-control none_border" id="side" name="side" defaultValue={inputsValue.side} onChange={handleChange} onFocus={e => {
                                        setFocusNumeroInterior(true)
                                        setInputActive(true)
                                        setShowFooter(false)
                                    }} onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.side === '') {
                                            setFocusNumeroInterior(false)
                                        }
                                        setInputActive(false)
                                    }} />
                                    {inputsErrors.side && (
                                        <span id="ht-side" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.side}</span>
                                    )}
                                </div>
                                </div> : ''
                            }
                            { (enableType) ?
                                <div className="col-12">
                                <div className={["form-group", "bmd-form-group", ((focusNumeroInterior || inputsValue.type) ? "is-focused" : ""), inputsErrors.type && 'error'].join(" ")}>
                                    <label htmlFor="type" className="bmd-label-floating">Tipo:</label>
                                    <input type="text" className="form-control none_border" id="type" name="type" defaultValue={inputsValue.type} onChange={handleChange} onFocus={e => {
                                        setFocusNumeroInterior(true)
                                        setInputActive(true)
                                        setShowFooter(false)
                                    }} onBlur={e => {
                                        setShowFooter(true)
                                        if (inputsValue.type === '') {
                                            setFocusNumeroInterior(false)
                                        }
                                        setInputActive(false)
                                    }} />
                                    {inputsErrors.type && (
                                        <span id="ht-type" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.type}</span>
                                    )}
                                </div>
                                </div> : ''
                            }
                        </div>

                        <div className="break_float"></div>
                        <br />
                        {/* <table className="ubicacionGPS">
                            <tbody>
                                <tr>
                                    <th><div className="location_icon"></div></th>
                                    <th>Validar ubicación<br></br><small>Validar via GPS ahora</small></th>
                                    <th>
                                        <div className="checkboxswitch">
                                            <label htmlFor="check_switch" className="switchDir" >
                                                <input id="check_switch" type="checkbox" onChange={e => geoLocalizacion()} />
                                                <span className="sliderDir round"></span>
                                            </label>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table> */}
                        <span id="ht-gps" className="helper-text ht" data-error="wrong" data-success="right"></span>
                        <div id="out"></div>
                        <br /><br />
                        <button type="button" disabled={!continuar} className={["btn", "btn-raised", "forcewidth100", ((!continuar) ? "btn-secondary" : "btn-primary main_bg_color")].join(" ")} onClick={e => actualizarDatos(e)}>GUARDAR</button>
                    </form>
                </div>
                {showFooter && <Footer />}
                {/* {errorUbicacion ?
                    <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Atención</h5>
                                    <button onClick={e => setErrorUbicacion(false)} className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Necesitamos validar tu ubicación para continuar</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={e => setErrorUbicacion(false)} className="btn btn-raised btn-primary main_bg_color">ENTENDIDO</button>
                                </div>
                            </div>
                        </div>
                    </div> : null} */}
            </div>
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </Fragment>
    )
}

export default IdentificacionEdit