import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
    sendEventClick, evento, status, statusData, statusError, statusReintento, enviarImagenVideotoken,
    mapearRespuesta,
    setLocalStorageResultFace,
    mapearError,
    mapearMensajeError,
    convertBase64ToBlob,
    flowIncompleted,
    generateZip,
    validarOtorganteCDC,
    cancelarINE,
    cancelarRENAPO
} from "../../services/data";
import CancelarFlujo from "../../pages/cancelar_flujo";
import { configStyle, obtenerValorConfig } from '../../services/configStyle';
import { isIOS, isMobile, withOrientationChange } from 'react-device-detect';
import Loader from "../loader";
import ErrorMessageAlert from "../alerts_modals/error_message_alert";
import LimitIntentsAlert from "../alerts_modals/limit_intents";
import { cancelarRequest, cancelarRequestIne, cancelarRequestRenapo, getDocuments } from "../../services/api";

function Iframe(props) {
    const linkIframe = "https://liveness-interactivo.vercel.app/"
    // const linkIframe = "https://74ad-2806-2a0-111e-8151-1dd2-35af-3517-333f.ngrok.io"

    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [apiKey, setApiKey] = useState('');
    const [reintentos, setReintentos] = useState(0);
    const [intentos, setIntentos] = useState(0);
    const [bloqueo, setBloqueo] = useState(false);
    const [showAlertRetry, setShowAlertRetry] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [response, setResponse] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [uuidTransaccion, setUuidTransaccion] = useState('');
    const [pathname, setPathname] = useState('')
    const [scorePorcentage, setScorePorcentage] = useState(false)
    const [showIframe, setShowIframe] = useState(true)
    const serviceCall = 'videotoken-embedding'
    const type = 'selfie';
    var frame;


    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
            //console.log(apiKey);
        }

        let uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx !== null)
            setUuidTransaccion(uuidTrx);

        setPathname(window.location.pathname)

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }

        let block = localStorage.getItem('bloqueo')
        if (block == "true") {
            setBloqueo(true);
            setTimeout(() => {
                terminariFramePostMessage()
            }, 5000);
        }

        status("captura_video_token_page", "Captura Video Token")

        frame = document.getElementById("youriframe");
        frame = frame ? frame.contentWindow : null;
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)

        let intents = (Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_VIDEO_TOKEN', 5)))
        intents && setIntentos(intents)

    }, [dataOtorgante])

    useEffect(() => {
        if (reintentos > 0 && errorStatus) {
            console.log(reintentos);
            statusR("Intentos Video Token", reintentos)

            if (reintentos === intentos) {
                localStorage.setItem('bloqueo', "true");
                terminariFramePostMessage();
                obtenerDocumentos()
                setShowAlertRetry(false)
            } else {
                statusR("Captura Video Token", errorStatus)
            }
        }
    }, [reintentos, errorStatus])

    const messageEvents = (mensaje) => {
        let tipo = '';
        let informacion = mensaje.data
        if (mensaje.origin !== new URL(linkIframe).origin) {
            return
        }
        // console.log(mensaje.data);
        if (mensaje.data === "Falló la carga del modelo") {
            console.log("Falló la carga del modelo");
            evento('Iframe Liveness Interactivo', 'Success', informacion, true);
            setShowAlertRetry(true)
            setMensajeError(mensaje.data)
        } else if (mensaje.data === "Dispositivo no admitido") {
            console.log("Dispositivo no admitido");
            evento('Iframe Liveness Interactivo', 'Success', informacion, true);
            cancelarFLujo()
            // setTimeout(() => {
            //     history.push('/' + apiKey)
            // }, 5000);
        } else if (mensaje.data === "No se pudo grabar") {
            console.log("No se pudo grabar");
            evento('Iframe Liveness Interactivo', 'Success', informacion, true);
            setShowAlertRetry(true)
            setMensajeError(mensaje.data)

        } else if (mensaje.data === "Error al iniciar video") {
            console.log("Error al iniciar video");
            evento('Iframe Liveness Interactivo', 'Success', informacion, true);
            setShowAlertRetry(true)
            setMensajeError(mensaje.data)

        } else if (mensaje.data === "Cargue Modelos") {
            console.log("Cargue Modelos");
            evento('Iframe Liveness Interactivo', 'Success', informacion, true);
            if (bloqueo == true) terminariFramePostMessage()

        } else if (mensaje.data === "Excedio tiempo de validacion liveness General" ||
            mensaje.data === "Timeout") {
            console.log("Excedio tiempo de validacion liveness General");
            evento('Iframe Liveness Interactivo', 'Success', informacion, true)
            setShowAlertRetry(true)
            setMensajeError("Excedio tiempo de validacion")
            setReintentos(reintentos + 1)
            setErrorStatus("Excedio tiempo de validacion")

        } else if (mensaje.data === "No se detectó rostro valido") {
            console.log("No se detectó rostro valido");
            evento('Iframe Liveness Interactivo', 'Success', informacion, true);
            setMensajeError(mensaje.data)
            setShowAlertRetry(true)
            setReintentos(reintentos + 1)
            setErrorStatus(mensaje.data)

        } else if (mensaje.data === "Error al guardar video en el server") {
            console.log("Error al guardar video en el server");
            evento('Iframe Liveness Interactivo', 'Success', informacion, true);

        } else if (mensaje.data === "Error al guardar imagen en el server") {
            console.log("Error al guardar imagen en el server");
            evento('Iframe Liveness Interactivo', 'Success', informacion, true);

        } else if (mensaje.data === "Persona real") {
            console.log("Persona real");
            evento('Iframe Liveness Interactivo', 'Success', informacion, true);
            // setTimeout(() => {
            //     //history.push("/consulta_historial");
            //     console.log('DETECTADO');
            // }, 1000);
        } else if (mensaje.data.includes("base64")) {
            // Acepta base64 solo hay que cambiar este valor en la peticion
            // "service_call": "facecomparison_v1",
            // v1 es base v2 es archivo
            enviarImagen(mensaje.data)
        } else if (mensaje.data.includes("Cancelar")) {
            setTimeout(() => {
                history.push('/preparacion_liveness');
            }, 300);
        } else if (mensaje.data.includes("clase")) {
            setScorePorcentage(true);
            let clasificacion = mensaje.data.split(',')[1];
            let porcentaje = mensaje.data.split(',')[2];
            console.log('PORCENTAJE ', porcentaje, " CLASIFICACION ", clasificacion);
            localStorage.setItem("scoreObject", JSON.stringify({
                claseLiveness: clasificacion.trim(),
                porcentajeLiveness: parseFloat(porcentaje.trim())
            }))
            setScorePorcentage(true);
        }
    }

    const cancelarFLujo = async () => {
        setLoading(true)
        cancelarINE();
        cancelarRENAPO();
        cancelarRequest();
        cancelarRequestRenapo()
        cancelarRequestIne()
        sendEventClick('Captura Video Token', 'SÍ', { status: 'CANCELADO' })
        localStorage.setItem('flag', 'cancelado')
        await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("captura_video_token", 'Cancelado', "cancelado")
        if (!window.opener) {
            // if (validarRedireccionamiento(dataOtorgante)) {
            setTimeout(() => {
                history.push('/' + apiKey)
                setLoading(false)
            }, 5000);
            // }
        }
    }

    const canceliFramePostMessage = () => {
        document.getElementById("youriframe").contentWindow.postMessage("Cancelar", '*');
    }

    const retryiFramePostMessage = () => {
        document.getElementById("youriframe").contentWindow.postMessage("Reintentar", '*');
    }

    const terminariFramePostMessage = () => {
        document.getElementById("youriframe").contentWindow.postMessage("Terminar", '*');
    }

    useEffect(() => {
        window.addEventListener('message', messageEvents)
        return () => {
            window.removeEventListener('message', messageEvents)
        }
    }, [messageEvents])

    const statusR = async (paso, data) => {
        //Considerar los reintentos
        await statusReintento(paso, data, "RETRY");
    }

    // const statusCancelado = async () => {
    //     //Agregar todas las funciones
    //     //console.log("entre a status cancelar");
    //     cancelarINE();
    //     cancelarRENAPO();
    //     cancelarRequest();
    //     cancelarRequestRenapo()
    //     cancelarRequestIne()
    //     sendEventClick('Captura Video Token', 'SÍ', { status: 'CANCELADO' });
    //     localStorage.setItem('flag', 'cancelado');
    //     this.setState({ isComplete: true, hasResponse: false });
    //     await generateZip('TRUNCOS', 'trunco', isIOS);
    //     statusError("captura_video_token", 'Cancelado', "cancelado");
    //     if (window.opener) {
    //         setTimeout(() => {
    //             window.close();
    //         }, 300);
    //     } else {
    //         if (validarRedireccionamiento(this.state.dataOtorgante)) {
    //             setTimeout(() => {
    //                 this.props.history.push("/" + this.state.apikey);
    //                 this.setState({ isComplete: false, hasResponse: true });
    //             }, 1500);
    //         } else {
    //             this.setState({ showCancelScreen: true, cancelVideoToken: false, estado: 'cancelado' });
    //         }
    //     }
    // }

    const obtenerDocumentos = async () => {
        try {
            const response = await getDocuments();
            const { status, data, data: { payload } } = response
            evento('Obtener documentos', 'Succes', mapearRespuesta(status, data), true)
            if (status === 200) {
                if (payload.links.length > 0) {
                    let links = JSON.stringify(payload.links);
                    localStorage.setItem('links', links);
                }
            }
            setTimeout(() => {
                finalizarFlujo()
            }, 300);
        } catch (error) {
            let errorMapeado = mapearError(error)
            evento('Obtener documentos', errorMapeado.tipoError, errorMapeado.objetoError, false)
            setTimeout(() => {
                finalizarFlujo()
            }, 300);
        }
    }

    const finalizarFlujo = async (response) => {
        await generateZip('SEMAFORO', 'red', isIOS);
        localStorage.setItem('bloqueo', 'true');
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem('time', acceptDta.getTime())
        let res = convertdataJSON();
        let event_t = await JSON.stringify(res);
        var json_final = {};
        let status = "FAIL"
        json_final = {
            "status": status,
            "step": "finished",
            "description": event_t,
            'response': response,
            "finished": true
        }
        let event = JSON.stringify(json_final);
        await flowIncompleted({ 'step': 'captura_video_token', 'event': event }, 'red');
    }

    const convertdataJSON = () => {
        let jsonObj = {};
        jsonObj.uuidUser = localStorage.getItem('uuidUser') || '';
        jsonObj.uuidTrx = localStorage.getItem('uuidTrx') || '';
        jsonObj.ocrFront = JSON.parse(localStorage.getItem('ocrFront')) || {};
        jsonObj.ocrBack = JSON.parse(localStorage.getItem('ocrBack')) || {};
        jsonObj.data_user = JSON.parse(localStorage.getItem('data_user')) || {};
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
        return jsonObj;
    }

    const sendData = (match) => {
        //Cuando es exitoso, revisar la bandera  de speach
        statusData("captura_video_token", { coincidencia: match })
    }

    const enviarImagen = async (image) => {
        console.log("image", image);
        setLoading(true);
        if (image) {
            let imageBlob = await convertBase64ToBlob(image)
            console.log("imageBlob", imageBlob);

            try {
                const response = await enviarImagenVideotoken(imageBlob, isIOS, serviceCall)
                const { status, data } = response
                if (status === 200) {
                    evento('Captura videtoken', 'Succes', mapearRespuesta(status, data), true)
                    if (type === 'selfie')
                        sendData(data.payload.match)
                    setLocalStorageResultFace(data.payload)
                    // if (type === 'pdf') localStorage.setItem('enlace', data.payload.documentLink)
                    setLoading(false);
                    // if (scorePorcentage) {
                    setTimeout(() => {
                        setReintentos(0);
                        localStorage.setItem("bloqueo", false);
                        if (validarOtorganteCDC() || apiKey === "akAulrIASyELFzJehbqr969aXZLkEpQd")
                            history.push('/finalizado')
                        else
                            history.push('/consulta_historial')
                    }, 300);
                    // } else {
                    //     history.push('/preparacion_liveness')
                    // }
                }
            } catch (error) {
                let errorMapeado = mapearError(error)
                evento('Captura videtoken', errorMapeado.tipoError, errorMapeado.objetoError, false)
                if (errorMapeado.objetoError.tipo === 'RESPONSE') {
                    setResponse(errorMapeado)
                    let mensaje = ''
                    if (error.response.data) {
                        mensaje = error.response.data.message_client
                    }
                    let errorMessage = mapearMensajeError(mensaje)
                    if (error.response.status === 500 || error.response.status === 400) {
                        if (mensaje === 'No se encontro rostro' || mensaje === 'Se detecto cubrebocas' || mensaje === 'Condiciones de luz no adecuadas' || mensaje === 'Imagen borrosa' || mensaje === 'Se detectaron multiples caras' || mensaje === 'Falta de luz' || mensaje === 'No se pudo realizar la comparacion de rostro' || mensaje === 'No logramos detectar un rostro') {
                            setMensajeError(errorMessage)
                            setErrorStatus(errorMessage)
                            setError(true)
                            setLoading(false)
                        } else {
                            setReintentos(reintentos + 1)
                            setMensajeError(errorMessage)
                            setErrorStatus(errorMessage)
                            setError(true)
                            setLoading(false)
                        }
                    } else if (error.response.status === 404) {
                        setMensajeError(errorMessage)
                        setErrorStatus(errorMessage)
                        setError(true)
                        setLoading(false)
                        setReintentos(reintentos + 1)
                    } else {
                        setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                        setErrorStatus('Revisa tu conexión a internet e intenta nuevamente')
                        setError(true)
                        setLoading(false)
                    }
                } else {
                    if (error.toString().includes('Network')) {
                        setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                        setErrorStatus('Revisa tu conexión a internet e intenta nuevamente')
                        setError(true)
                        setLoading(false)
                    } else if (error.toString().includes('cancel')) {
                        setMensajeError('Se canceló el flujo')
                        setErrorStatus('Se canceló el flujo')
                        setLoading(false)
                    } else {
                        setMensajeError('El servicio no se encuentra disponible, lo solucionaremos en breve')
                        setErrorStatus('Error en la captura')
                        setError(true)
                        setLoading(false)
                    }
                }
                // if (videoRef.current) {
                //     videoRef.current.classList.remove("blur_video")
                // }
            }
        }
        setLoading(false);
    }

    //FUNCTION ON RETRY FOR ERROR API REQUEST
    const functionOnRetryApiError = () => {
        retryiFramePostMessage();
        setError(false)
        setMensajeError('')
        // evento('Captura ID', 'Click', { description: 'VOLVER A INTENTAR' }, true);
    }

    //FUNCTION ON RETRY FOR ERROR iFRAME RETRY
    const functionOnRetryIframeError = () => {
        setShowAlertRetry(false)
        setMensajeError('')
        retryiFramePostMessage();
        //evento('Captura ID', 'Click', { description: 'VOLVER A INTENTAR' }, true);
    }

    const functionOnCancel = () => {
        canceliFramePostMessage()
    }

    const functionOnOut = () => {
        canceliFramePostMessage()
        sendEventClick('Captura Video Token', 'CANCELAR')
        history.push('/' + apiKey)
    }

    return (
        <div id="webview" className="main_gradient">
            <div className="contentIframe" >
                {/* {showIframe ? ( */}
                <iframe
                    id="youriframe"
                    autoPlay={true}
                    allowusermedia="true"
                    src={linkIframe}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    className="iframeV"
                    allow="camera *; microphone *; autoplay"
                />
                {/* ) : (
                    <div></div>
                )} */}
            </div>
            {/* <div className="btn-cancel-iframe col-12" style={{ position: "absolute", bottom: '0%', display: 'flex', justifyContent: 'center' }}>
                <Link to="/inicio" className="btn btn-secondary" data-dismiss="modal" onClick={() => sendEventClick('Captura Video Token', 'CANCELAR')}>Cancelar</Link>
            </div> */}

            {(showAlertRetry && (reintentos < intentos)) &&
                <ErrorMessageAlert
                    errorMessage={mensajeError}
                    functionOnRetry={functionOnRetryIframeError}
                    functionOnCancel={functionOnCancel}
                />
            }

            {(reintentos >= intentos) && (
                <LimitIntentsAlert
                    functionOnOut={functionOnOut}
                    uuidTransaccion={uuidTransaccion}
                    dataOtorgante={dataOtorgante}
                    pathname={pathname}
                />
            )}

            {bloqueo && (
                <LimitIntentsAlert
                    functionOnOut={functionOnOut}
                    uuidTransaccion={uuidTransaccion}
                    dataOtorgante={dataOtorgante}
                    pathname={pathname}
                />
            )}

            {error && mensajeError && reintentos < intentos && (
                <ErrorMessageAlert
                    errorMessage={mensajeError}
                    functionOnRetry={functionOnRetryApiError}
                    functionOnCancel={functionOnCancel}
                />
            )}

            {loading && (
                <Loader />
            )}
        </div>
    )
}

export default Iframe;