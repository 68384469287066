import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
import { configStyle, positionDiv } from '../services/configStyle';
import { generateZip, hayExcepcion, status, statusError, evento, sendEventClick, validarRedireccionamiento } from '../services/data';
import Ayuda from './ayuda';
import { isMobile, isIOS } from 'react-device-detect';
import CancelarFlujo from '../components/cancelar_flujo';
import Loader from '../components/loader'

const PreparacionIne = () => {

    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [showCancel, setShowCancel] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const { ruta } = useParams();
    const divFooterRef = useRef(null);
    const [showHelp, setShowHelp] = useState(false);
    const [step, setStep] = useState(null);
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }

        let uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) {
            setUuidTransaccion(uuidTransaccionLS)
        }

        status("preparacion_id_page", "Preparacion ID");
        setStep('/identificacion');
        const divFooter = divFooterRef.current;
        divFooter.style.position = positionDiv(window.innerWidth, window.innerHeight);
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    const statusE = async () => {
        setLoading(true)
        sendEventClick('Preparacion ID', 'SÍ', { status: 'CANCELADO' })
        let data = "Cancelado"
        localStorage.setItem('flag', 'cancelado');
        await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("preparacion_id_page", data, "cancelado")
        if (!window.opener) {
            if (validarRedireccionamiento(dataOtorgante)) {
                setTimeout(() => {
                    history.push("/" + (ruta !== "pdf_video_token" ? apiKey : `pdf_token?uuid=${localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("idOtorgante")}&fecha=${localStorage.getItem("fechavigencia")}`))
                    setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
                setShowCancel(false)
            }
        }
    }

    return (
        <>
            {(showCancel) ?
                <div className="modal fade show" style={{ display: "block" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={e => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setShowCancel(false); sendEventClick('Preparacion ID', 'NO', {}); }} className="btn btn-secondary modal_btn_w" data-dismiss="modal">NO</button>
                                <button type="button" onClick={e => statusE()} className="btn btn-raised btn-secondary modal_btn_w">SÍ</button>
                            </div>
                        </div>
                    </div>
                </div> : ("")}
            <div className="main_gradient container-flex">
                <Header ruta="preparacion_identificacion" show={() => setShowHelp(true)} />
                {(hayExcepcion()) ?
                    <div>
                        <div className="main_text_container custom_padding">
                            <h1 className="animate__animated">Tómale una foto a tu identificación oficial</h1>
                            <p className="animate__animated">Para capturar tu DNI asegúrate que se encuentre <b>vigente</b>, evita <b>reflejos</b>, de preferencia frente a una <b>superficie obscura</b> para mejorar el contraste.</p>
                        </div>
                        <div className="row id_type_bx">
                            <div className="col-12">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <h6>DNI</h6>
                                    <div className="maskface id_central"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="main_text_container custom_padding">
                            <h1 className="animate__animated">Tómale una foto a tu identificación oficial</h1>
                            <br /><br />
                            <p className="animate__animated">Pero antes, <b>considera</b> estos puntos:
                                <br />
                                <div className="spaceLi">- Que esté <b>vigente</b>.</div>
                                <div className="spaceLi">- Toma una foto <b>enfocada</b> y <b>sin reflejos</b>.</div>
                            </p>
                            <br />
                            <p className="animate__animated">Identificaciones <b>permitidas</b>:</p>
                            <ul className="sub_list">
                                <li className="spaceLi"><em>Funcionario</em></li>
                                <li className="spaceLi"><em>Docente</em></li>
                                <li className="spaceLi"><em>Administrativo</em></li>
                                <li className="spaceLi"><em>Estudiante</em></li>
                            </ul>
                        </div>

                    </div>
                }
                <div className="action_buttons">
                    <Link to={{
                        pathname: step,
                        state: { passport: false }
                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={e => evento('Preparacion ID', 'Click', { description: 'CAPTURAR IDENTIFICACIÓN' }, true)}>
                        CAPTURAR IDENTIFICACIÓN</Link>
                    <button onClick={e => {
                        setShowCancel(true);
                    }} className="btn btn-primary forcewidth100 main_color">
                        CANCELAR</button>
                </div>
                <div className="div_bottom" ref={divFooterRef}>
                    <Footer />
                </div>
            </div>
            <div className={(showCancel ? "modal-backdrop show" : "")}></div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {loading && <Loader />}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'} />}
        </>
    )

}


export default PreparacionIne